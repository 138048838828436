import React, { RefObject } from 'react';
import { Formik, FormikProps } from "formik";
import {
    InviteUserRequest,
    unclaimedUserRequestInitialState
} from "features/users/api/request/inviteUserRequest";
import { Grid, TextField } from "@mui/material";
import { nameof } from "app/helpers/stringHelper";
import { userInviteValidation } from "features/users/models/userInviteValidation";
import { UserType } from "models/lookup/userType";
import { TextInput } from "components/input/textInput";
import {
    CreatePatientRequest,
    createPatientRequestInitialState
} from "features/patient/api/request/createPatientRequest";
import { patientCreationValidation } from "features/patient/models/patientCreationValidation";
import { useUserContext } from "app/contexts/userContext";

export interface PatientCreationFormProps {
    onSubmit: (request: CreatePatientRequest) => void;
    formik: RefObject<FormikProps<CreatePatientRequest>>;
}

const PatientCreationForm: React.FC<PatientCreationFormProps> = ({ onSubmit, formik }) => {
    
    const {user} = useUserContext();
    
    return (
        <Formik
            initialValues={{
                ...createPatientRequestInitialState,
                clinicianUserId: user?.id ?? ""
            }}
            onSubmit={onSubmit}
            validationSchema={patientCreationValidation}
            innerRef={formik}
            enableReinitialize
        >
            {({ values, handleChange, setFieldValue, errors, touched }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput name={nameof<CreatePatientRequest>("identifier")}
                                   label={"Identifier"}
                                   value={values.identifier}
                                   onChange={handleChange}
                                   error={touched.identifier && Boolean(errors.identifier)}
                                   helperText={touched.identifier && errors.identifier}
                        />
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}
export { PatientCreationForm }