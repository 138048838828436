import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { patientDirectoryColumns } from "features/clinician/models/patientDirectoryColumns";
import { flatten } from "app/helpers/objectHelper";
import { Patient } from "features/patient/models/patient";
import { patientApi } from "features/patient/api/patientApi";
import { Pagination, paginationInitialState } from "models/table/pagination";
import { nameof } from "app/helpers/stringHelper";
import { ArrowForwardIosRounded, Close, CloseRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BaseFilters } from "models/filters/baseFilters";
import { Programme } from "features/programme/models/programme";
import { programmeApi } from "features/programme/api/programmeApi";
import { programmeDirectoryColumns } from "features/programme/models/programmeDirectoryColumns";
import { CreateProgrammeRequest } from "features/programme/api/request/createProgrammeRequest";
import { ScenarioTemplate } from "features/programme/models/scenarioTemplate";
import { scenarioTemplateColumns } from "features/scenario/models/scenarioTemplateColumns";
import { scenarioApi } from "features/scenario/api/scenarioApi";

export type TemplateDirectoryProps = {
    templates: ScenarioTemplate[],
    updateTemplates: (templates: ScenarioTemplate[]) => void;
    filters: BaseFilters,
    pagination: Pagination
}

const TemplateDirectory: React.FC<TemplateDirectoryProps> = ({templates, updateTemplates, filters, pagination}) => {
    
    const navigate = useNavigate();
    
    const onDeleteClick = useCallback((templateId: string) => () => {
        scenarioApi.deleteTemplate(templateId).then(() => {
            updateTemplates(templates.filter(t => t.id !== templateId))
        })
    }, [navigate, templates, updateTemplates])
    
    const deleteTemplate: GridColDef = {
        headerName: "", field: nameof<Patient>("id"),
        align: "center",
        renderCell: (params) =>
            <IconButton color={"primary"} onClick={onDeleteClick(params.value)}><CloseRounded/></IconButton>
    } 
    
    return (
        <DataGrid
            autoHeight
            columns={[...scenarioTemplateColumns, deleteTemplate]}
            rows={templates.map((p) => flatten(p))}
            disableColumnMenu
            pagination
            paginationModel={pagination}
        />
    )
}
export { TemplateDirectory }