import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, CardContent, Container, Grid, Menu, Typography } from "@mui/material";
import { TitleCard } from "components/cards/titleCard";
import { ProgrammeDirectory } from "features/clinician/components/programmeDirectory";
import { BaseFilters, baseFiltersInitialState } from "models/filters/baseFilters";
import { AddRounded } from "@mui/icons-material";
import { PatientFiltersForm } from "features/patient/components/patientFiltersForm";
import { patientFiltersInitialState } from "features/patient/models/patientFilters";
import { CreateProgrammeForm } from "features/programme/components/createProgrammeForm";
import { Programme, programmeInitialState } from "features/programme/models/programme";
import { FormikProps } from "formik";
import { programmeApi } from "features/programme/api/programmeApi";
import { Pagination, paginationInitialState } from "models/table/pagination";
import { CreateProgrammeRequest } from "features/programme/api/request/createProgrammeRequest";

const Programmes: React.FC = () => {

    const [programmes, setProgrammes] = useState<Programme[]>([])
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const [filters, setFilters] = useState<BaseFilters>(baseFiltersInitialState);
    const [pagination, setPagination] = useState<Pagination>(paginationInitialState);

    const formik = useRef<FormikProps<CreateProgrammeRequest>>(null);
    
    const open = Boolean(anchor);

    useEffect(() => {
        programmeApi.getProgrammes(filters, pagination).then((data) => {
            setProgrammes(data)
        })
    }, [filters, pagination])

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);

    const createProgramme = useCallback((values: CreateProgrammeRequest) => {
        programmeApi.createProgramme(values)
            .then(programme => setProgrammes(prevState => [...prevState, programme]))
    }, [])

    return (
        <Container maxWidth={'lg'}>
            <TitleCard
                title={"Programmes"}
                toolbar={
                    <Button color={"success"} startIcon={<AddRounded/>} onClick={handleClick}>
                        Create Programme
                    </Button>}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <ProgrammeDirectory
                            programmes={programmes}
                            filters={filters}
                            pagination={pagination}
                        />
                    </Grid>
                </Grid>
            </TitleCard>
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant={"menu"}
            >
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CreateProgrammeForm
                                initialValues={programmeInitialState}
                                onSubmit={createProgramme}
                                formik={formik}
                            />
                        </Grid>
                        <Grid container item justifyContent={"flex-end"}>
                            <Grid item>
                                <Button color={"success"} onClick={() => formik.current?.submitForm()}>Submit</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Menu>
        </Container>
    )
}
export { Programmes }