import { GridColDef } from "@mui/x-data-grid";
import { User } from "features/users/models/user";
import { getInitials, nameof } from "app/helpers/stringHelper";
import { Avatar, Box, Checkbox } from "@mui/material";
import { CheckRounded, CloseRounded } from "@mui/icons-material";
import { Patient } from "features/patient/models/patient";
import React from "react";

export const patientDirectoryColumns: GridColDef[] = [
    {
        headerName: "Identifier",
        field: nameof<Patient>("identifier"),
        flex: 0.25,
    }
]