import React, { useEffect } from "react";

import { User } from "features/users/models/user";
import { AccountInfo } from "@azure/msal-browser";
import { Clinician } from "features/clinician/models/clinician";
import { Props } from "models/interfaces/props";
import { UserType } from "models/lookup/userType";
import { clinicianApi } from "features/clinician/api/clinicianApi";
import { useUserContext } from "app/contexts/userContext";
import { Technician } from "features/technician/models/technician";
import { technicianApi } from "features/technician/api/technicianApi";

type TechnicianContextState = {
    technician: undefined | Technician,
    updateTechnician: (user: Technician) => void,
}


export const TechnicianContext = React.createContext<TechnicianContextState>({
    technician: undefined,
    updateTechnician: () => {
    },
})

export const useTechnicianContext = () => React.useContext(TechnicianContext)

const TechnicianBroker: React.FC<Props> = ({ children }) => {

    const { user } = useUserContext()
    const { updateTechnician } = useTechnicianContext()

    useEffect(() => {
        if (user !== undefined && (user.type === UserType.Admin || user.type === UserType.Technician)) {
            technicianApi.getTechnicianFromContext().then(response => {
                updateTechnician(response)
            })
        }
    }, [user])

    return (children)
}
export { TechnicianBroker }