import React, { useEffect } from "react";

import { User } from "features/users/models/user";
import { AccountInfo } from "@azure/msal-browser";
import { Clinician } from "features/clinician/models/clinician";
import { Props } from "models/interfaces/props";
import { UserType } from "models/lookup/userType";
import { clinicianApi } from "features/clinician/api/clinicianApi";
import { useUserContext } from "app/contexts/userContext";

type ClinicianContextState = {
    clinician: undefined | Clinician,
    updateClinician: (user: Clinician) => void,
}


export const ClinicianContext = React.createContext<ClinicianContextState>({
    clinician: undefined,
    updateClinician: () => {
    },
})

export const useClinicianContext = () => React.useContext(ClinicianContext)

const ClinicianBroker: React.FC<Props> = ({ children }) => {

    const { user } = useUserContext()
    const { updateClinician } = useClinicianContext()

    useEffect(() => {
        if (user !== undefined && (user.type === UserType.Admin || user.type === UserType.Clinician)) {
            clinicianApi.getClinicianFromContext().then(response => {
                updateClinician(response)
            })
        }
    }, [user])

    return (children)
}
export { ClinicianBroker }