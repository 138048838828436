import { GridColDef } from "@mui/x-data-grid";
import { User } from "features/users/models/user";
import { nameof } from "app/helpers/stringHelper";
import { Checkbox } from "@mui/material";
import { Patient } from "features/patient/models/patient";
import { Programme } from "features/programme/models/programme";

export const programmeDirectoryColumns: GridColDef[] = [
    {
        headerName: "Name",
        field: nameof<Programme>("name"),
        flex: 0.3
    },
    {
        headerName: "Description",
        field: nameof<Programme>("description"),
        flex: 0.25,
    },
    {
        headerName: "Scenarios",
        field: nameof<Programme>("scenarios"),
        width: 120,
        align: "center",
        headerAlign: "center",
        valueFormatter: (params: any) => params.length
    },
    {
        headerName: "Active",
        width: 100,
        field: nameof<Programme>("active"),
        renderCell: (params) => <Checkbox checked={params.value} disabled/>,
        align: "center",
        headerAlign: "center"
    }
]