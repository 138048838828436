import React from 'react';
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";

import { useMsal } from '@azure/msal-react';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';

import { ThemeLayout } from "app/layouts/themeLayout";

import { msalApi } from "features/authentication/api/msalApi";

import { useUserContext } from "app/contexts/userContext";
import { authenticationApi } from "features/authentication/api/authenticationApi";
import { User } from "features/users/models/user";
import { UserDashboard } from "pages/user/userDashboard";
import { ClinicianDashboard } from "pages/clinician/clincianDashboard";
import { PatientDashboard } from "pages/patient/patientDashboard";
import { ClinicianLayout } from "app/layouts/clinicianLayout";
import { PatientLayout } from "app/layouts/patientLayout";
import { UserLayout } from "app/layouts/userLayout";
import { UserType } from "models/lookup/userType";
import { Patients } from "pages/clinician/patients";
import { PatientProfile } from "pages/clinician/patientProfile";
import { TechnicianLayout } from "app/layouts/technicianLayout";
import { TechnicianDashboard } from "pages/technician/technicianDashboard";
import { Devices } from "pages/technician/devices";
import { Programmes } from "pages/programme/programmes";
import { ProgrammeView } from "pages/programme/programmeView";
import { Templates } from "pages/templates/templates";
import { AdminLayout } from "app/layouts/adminLayout";
import { AdminDashboard } from "pages/admin/adminDashboard";
import { CustomerProfile } from "pages/admin/customers/customerProfile";

const AuthenticatedApp: React.FC = () => {

    const { accounts, inProgress } = useMsal();
    const { user, updateAccount, updateUser } = useUserContext()

    const navigate = useNavigate();

    React.useEffect(() => {
        if (inProgress === InteractionStatus.None && accounts.length > 0) {
            msalApi.getSilentToken(accounts[0].username).then(async r => {

                const accountInfo = r.account as AccountInfo;
                updateAccount(accountInfo);

                localStorage.setItem("username", accountInfo.username);

                authenticationApi.validateUser(accountInfo).then((user: User) => {
                    updateUser(user);
                })
            })
        }
    }, [inProgress, accounts])

    React.useEffect(() => {

        if (user) {
            switch (user.type) {
                case UserType.Admin:
                    if (!location.pathname.includes("/admin"))
                        navigate("/admin")
                    break;
                case UserType.Clinician:
                    if (!location.pathname.includes("/clinician"))
                        navigate("/clinician")
                    break;
                case UserType.Patient:
                    if (!location.pathname.includes("/patient"))
                        navigate("/patient")
                    break;
                case UserType.Technician:
                    if (!location.pathname.includes("/technician"))
                        navigate("/technician")
                    break;
                default:
                    if (!location.pathname.includes("/-/"))
                        navigate("/-/")
            }
        }

        if (location.pathname === "/") {
            navigate(`/-/`);
        }
    }, [user])

    return (
        <ThemeLayout>
            <Routes>
                <Route path={"/-/"} element={<UserLayout><Outlet/></UserLayout>}>
                    <Route index element={<UserDashboard/>}/>
                </Route>
                <Route path={"/clinician"} element={<ClinicianLayout><Outlet/></ClinicianLayout>}>
                    <Route index element={<ClinicianDashboard/>}/>
                    <Route path={"patients"}>
                        <Route index element={<Patients/>}/>
                        <Route path={":patientId"} element={<PatientProfile/>}/>
                    </Route>
                    <Route path={"programmes"}>
                        <Route index element={<Programmes/>}/>
                        <Route path={":programmeId"} element={<ProgrammeView/>}/>
                    </Route>
                    <Route path={"templates"}>
                        <Route index element={<Templates/>}/>
                    </Route>
                </Route>
                <Route path={"/patient"} element={<PatientLayout><Outlet/></PatientLayout>}>
                    <Route index element={<PatientDashboard/>}/>
                </Route>
                <Route path={"/technician"} element={<TechnicianLayout><Outlet/></TechnicianLayout>}>
                    <Route index element={<TechnicianDashboard/>}/>
                    <Route path={"devices"} element={<Devices/>}/>
                </Route>
                <Route path={"/admin"} element={<AdminLayout><Outlet/></AdminLayout>}>
                    <Route index element={<AdminDashboard/>}/>
                    <Route path={"customers"}>
                        <Route path={":customerId"} element={<CustomerProfile/>}/>
                    </Route>
                </Route>
            </Routes>
        </ThemeLayout>
    )
}
export { AuthenticatedApp }