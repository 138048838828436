import { subDays, subMonths } from "date-fns";
import { BaseFilters, baseFiltersInitialState } from "models/filters/baseFilters";

export type MetricFilters = BaseFilters & {
    createdAfter: Date;
    createdBefore: Date;
}

export const metricFiltersInitialState: MetricFilters = {
    ...baseFiltersInitialState,
    createdAfter: subMonths(new Date(), 1),
    createdBefore: new Date()
}