import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Grid,
    LinearProgress, Link,
    Tab,
    Tabs,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {useParams} from "react-router-dom";
import {customerApi} from "features/customer/api/customerApi";
import {Customer, customerInitialState} from 'features/customer/models/customer';
import {ProfileHeading} from "features/patient/components/profile/profileHeading";
import {CustomerProfileOptions} from "features/customer/models/customerProfileOptions";
import {CustomerOverview} from "pages/admin/customers/customerOverview";
import {CustomerUsers} from "pages/admin/customers/customerUsers";
import {TabLayout} from "components/layouts/tabLayout";
import {AddLink, AddLinkRounded, CastRounded, LinkRounded, PersonAddRounded, PersonRemoveRounded} from "@mui/icons-material";
import {useUserContext} from "../../../app/contexts/userContext";
import {userApi} from "../../../features/users/api/userApi";

const CustomerProfile: React.FC = () => {

    const [customer, setCustomer] = useState<Customer>(customerInitialState)
    const [tab, setTab] = useState<CustomerProfileOptions>(CustomerProfileOptions.Overview)

    const {customerId} = useParams();

    const { user, updateUser } = useUserContext()

    useEffect(() => {

        if (customerId === undefined)
            return;

        customerApi.getCustomer(customerId).then((response) => setCustomer(response))

    }, [customerId])

    const assignUserToCustomer = useCallback(() => {
        if(user !== undefined && customerId !== undefined){
            userApi.changeUserCustomer(user?.id, customerId).then(() => {
                updateUser({
                    ...user,
                    customerId: customerId
                })
            })
        }
    }, [user, customerId, updateUser])

    // @ts-ignore
    return (
        <Container maxWidth={'lg'}>
            <Card sx={{mb: 4}}>
                <CardContent>
                    <ProfileHeading
                        name={customer.name}
                        email={customer.billingEmail}
                        disableAvatar
                        toolbar={
                            <Grid container item spacing={2} alignItems={"center"}>
                                <Grid container item xs spacing={2}>
                                    <Grid item>
                                        {user?.customerId === customerId ?
                                            <Button size={"small"} color={"error"} disabled
                                                    startIcon={<LinkRounded/>}>
                                                You're linked to this customer!
                                            </Button>
                                            :
                                            <Button onClick={assignUserToCustomer} size={"small"} color={"success"}
                                                    startIcon={<AddLinkRounded/>}>
                                                Assign yourself to {customer.name}
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    />
                </CardContent>
                <Divider/>
                <CardContent>
                    <CustomerOverview customer={customer}/>
                </CardContent>
            </Card>
            <CustomerUsers
                customer={customer}
                updateCustomer={setCustomer}
            />
        </Container>
    )
}
export {CustomerProfile}