import React, { useCallback, useEffect, useState } from 'react';
import { flatten } from "app/helpers/objectHelper";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Pagination, paginationInitialState } from "models/table/pagination";
import { useNavigate } from "react-router-dom";
import { Device } from "features/device/models/device";
import { DeviceFilters } from "features/device/models/deviceFilters";
import { deviceApi } from "features/device/api/deviceApi";
import { deviceDirectoryColumns } from "features/device/models/deviceDirectoryColumns";
import { IconButton, Tooltip } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { nameof } from "app/helpers/stringHelper";

export interface DeviceDirectoryProps {
    devices: Device[];
    filters: DeviceFilters;
    updateDevices: (devices: Device[]) => void;
}

const DeviceDirectory: React.FC<DeviceDirectoryProps> = ({ devices, filters, updateDevices }) => {

    const [pagination, setPagination] = useState<Pagination>(paginationInitialState);

    const navigate = useNavigate();

    useEffect(() => {
        deviceApi.getDevices(filters, pagination).then((data) => {
            updateDevices(data)
        })
    }, [filters, pagination])

    const onDeviceDelete = useCallback((deviceId: string) => () => {
        deviceApi.deleteDevice(deviceId).then(() => {
            updateDevices(
                devices.filter(d => d.id !== deviceId)
            )
        })
    }, [updateDevices, devices])

    const actions: GridColDef[] = [
        {
            headerName: "",
            field: nameof<Device>("id"),
            width: 80,
            align: "center",
            renderCell: (params) => {

                const isAssigned = params.row.assigned;
                const tooltipTitle = isAssigned 
                    ? "Cannot delete assigned headset" 
                    :`Delete ${params.row.friendlyName}`

                return (
                    <Tooltip title={tooltipTitle} arrow placement={"right"}>
                        <span>
                        <IconButton onClick={onDeviceDelete(params.value)}
                                    disabled={isAssigned}><CloseRounded/></IconButton>
                        </span>
                    </Tooltip>
                )
            },
        }
    ]

    return (
        <DataGrid
            autoHeight
            columns={[...deviceDirectoryColumns, ...actions]}
            rows={devices.map((p) => flatten(p))}
            disableColumnMenu
            pagination
            paginationModel={pagination}
        />
    )
}
export { DeviceDirectory }