export type CreateScenarioRequest = {
    name: string,
    description: string,
    templateId: string;
    programmeId: string;
}

export const createScenarioRequestInitialState: CreateScenarioRequest = {
    name: "",
    description: "",
    templateId: "",
    programmeId: ""
}