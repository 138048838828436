import { AppBar, Avatar, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useUserContext } from "app/contexts/userContext";
import { getInitials } from "app/helpers/stringHelper";
import { UserSummary } from 'components/layouts/userSummary';

export interface UserAppBarProps {
}

const UserAppBar: React.FC<UserAppBarProps> = () => {

    const { user } = useUserContext();

    return (
        <AppBar component="nav">
            <Toolbar>
                <Grid container alignItems={"center"}>
                    <Grid container item xs alignItems={"center"}>
                        <Grid item>
                            <Typography variant={"h4"} color={"white"}>VIRTUE</Typography>
                        </Grid>
                    </Grid>
                    {user &&
                        <Grid item>
                            <UserSummary open firstName={user.firstName} surname={user.surname} color={"white"}/>
                        </Grid>
                    }

                </Grid>
            </Toolbar>
        </AppBar>
    )
}
export { UserAppBar }