import { layoutStyles } from "app/styling/themes/layout";
import { createTheme } from "@mui/material";

import colours from "app/styling/globals/colours.module.scss"

export const lightTheme = createTheme({
    ...layoutStyles,
    palette: {
        primary: {
            main: colours.primary
        },
        secondary: {
            main: colours.secondary
        },
        info: {
            main: colours.info
        },
        success: {
            main: colours.success
        },
        warning: {
            main: colours.warning
        },
        error: {
            main: colours.error
        },
        text: {
            primary: "#282c34",
            secondary: "#626c80"
        },
        background: {
            default: colours.background
        }
    },
})