import React, { useCallback, useState } from 'react';
import { Button, CardContent, Grid, Menu, TextField, Typography } from "@mui/material";
import { FilterAltRounded, SearchRounded } from "@mui/icons-material";
import { PatientFiltersForm } from "features/patient/components/patientFiltersForm";
import { patientFiltersInitialState } from '../models/patientFilters';

export type PatientFiltersToolbarProps = {}

const PatientFiltersToolbar: React.FC<PatientFiltersToolbarProps> = () => {

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const open = Boolean(anchor);

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);

    const handleSubmit = useCallback(() => {

    }, [])

    return (
        <>
            <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item>
                    <Button color={"inherit"} size={"large"} onClick={handleClick}
                            startIcon={<FilterAltRounded/>}>Filters</Button>
                </Grid>
                <Grid item>
                    <TextField
                        variant={"outlined"}
                        placeholder={"Search"}
                        InputProps={{ startAdornment: <SearchRounded color={"action"}/> }}
                    />
                </Grid>
            </Grid>
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                variant={"menu"}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <CardContent>
                            <Grid container item justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <Typography>Filters</Typography>
                                </Grid>
                                <Grid container item xs justifyContent={"flex-end"}>
                                    <Grid item>
                                        <Button color={"warning"}>Clear</Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button color={"success"}>Apply</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Grid>
                    <Grid item xs={12}>
                        <PatientFiltersForm initialValues={patientFiltersInitialState} onSubmit={handleSubmit}/>
                    </Grid>
                </Grid>
            </Menu>
        </>

    )
}
export { PatientFiltersToolbar }