import { User } from "features/users/models/user";
import { axiosApi } from "app/axios/axios";
import { InviteUserRequest } from "features/users/api/request/inviteUserRequest";
import { Pagination } from "models/table/pagination";
import { Clinician } from "features/clinician/models/clinician";
import { UrlBuilder } from "app/builders/urlBuilder";
import { UserFilters } from "features/users/models/userFilters";

class ClinicianApi {
    
    private urlBuilder: UrlBuilder;
    
    constructor() {
        this.urlBuilder = new UrlBuilder("api/clinician")
    }
    
    public async getClinicianFromContext(): Promise<Clinician> {
        
        const url = this.urlBuilder
            .build();
        
        return await axiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            console.log(e)
            return e;
        })
        
    }

    public async getClinicians(filters: UserFilters, pagination: Pagination): Promise<Clinician[]> {
        
        const url = this.urlBuilder
            .withRoute("All")
            .withPagination(pagination)
            .withUserFilters(filters)
            .build();
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }
    
    public async inviteClinician(request: InviteUserRequest): Promise<Clinician> {
        
        const url = this.urlBuilder
            .withRoute("InviteClinician")
            .build();
        
        return await axiosApi.post(url, JSON.stringify(request))
            .then((response) => response.data)
            .catch(e => e)
    }
    
    public async assignPatient(clinicianId: string, patientId: string): Promise<void> {
        
        const url = this.urlBuilder
            .withRoute("AssignPatient")
            .withRoute(clinicianId)
            .withRoute(patientId)
            .build()
        
        return await axiosApi.post(url)
    }

    public async unassignPatient(clinicianId: string, patientId: string): Promise<void> {

        const url = this.urlBuilder
            .withRoute("UnassignPatient")
            .withRoute(clinicianId)
            .withRoute(patientId)
            .build()

        return await axiosApi.delete(url)
    }
    
}

const clinicianApi = new ClinicianApi();
export { clinicianApi }