import React, { RefObject } from 'react';
import { Formik, FormikProps } from "formik";
import { Grid } from "@mui/material";
import { TextInput } from "components/input/textInput";
import { nameof } from "app/helpers/stringHelper";
import { CreateProgrammeRequest } from "features/programme/api/request/createProgrammeRequest";
import { createProgrammeValidation } from "features/programme/models/createProgrammeValidation";

export interface CreateProgrammeFormProps {
    initialValues: CreateProgrammeRequest;
    onSubmit: (values: CreateProgrammeRequest) => void;
    formik: RefObject<FormikProps<CreateProgrammeRequest>>;
}

const CreateProgrammeForm: React.FC<CreateProgrammeFormProps> = ({ initialValues, onSubmit, formik }) => {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formik}
            validationSchema={createProgrammeValidation}
        >
            {({ values, handleChange, setFieldValue, touched, errors }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                name={nameof<CreateProgrammeRequest>("name")}
                                label={"Name"}
                                value={values.name}
                                onChange={handleChange}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                name={nameof<CreateProgrammeRequest>("description")}
                                label={"Description"}
                                value={values.description}
                                onChange={handleChange}
                                multiline
                                rows={3}
                                error={touched.description && Boolean(errors.description)}
                                helperText={touched.description && errors.description}
                            />
                        </Grid>
                    </Grid>
                )
            }}
        </Formik>
    )
}
export { CreateProgrammeForm }