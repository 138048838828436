import { ThemeOptions } from "@mui/material";

import layout from "app/styling/globals/layout.module.scss"
import { CSSProperties } from "react";

const headerProps: CSSProperties = { fontWeight: 700};
export const elevation: number = 24;

export const layoutStyles: ThemeOptions = {
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: false
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    minWidth: "10px",
                    padding: "4px 6px"
                }
            },
            defaultProps: {
                disableElevation: true
            },
        },
        MuiCard: {
            defaultProps: {
                elevation: elevation
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: elevation
            },
            styleOverrides: {
                root: {
                    boxShadow: "0px 11px 14px -7px rgb(40 44 52 / 12%), 0px 23px 36px 3px rgb(40 44 52 / 8%), 0px 9px 44px 8px rgb(40 44 52 / 4%)"
                },
                outlined: {
                    boxShadow: "none"
                }
            }
        },
        MuiDrawer: {
            defaultProps: {
                elevation: elevation,
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 15,
                    padding: 10
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
                fullWidth: true
            },
        },
        MuiSelect: {
            defaultProps: {
                fullWidth:true
            }
        },
        MuiFormControl: {
            defaultProps: {
                fullWidth: true
            }
        },
        // @ts-ignore
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: "none",
                    "--DataGrid-containerBackground": "rgba(0,0,0,0)"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: layout.borderRadius
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textAlign: "inherit",
                    textTransform: "inherit",
                    borderRadius: layout.borderRadius
                },
                sizeSmall: {
                    padding: "4px 6px"
                }
            }
        }
    },
    typography: {
        fontFamily: layout.font,
        h1: { ...headerProps },
        h2: { ...headerProps },
        h3: { ...headerProps },
        h4: { ...headerProps },
        h5: { ...headerProps },
        h6: { ...headerProps },
    },
    shape: {
        borderRadius: parseInt(layout.borderRadius)
    },
}