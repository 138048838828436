import { AuditFilters } from "models/audit/auditFilters"
import { BaseFilters } from "models/filters/baseFilters";

export type DeviceFilters = AuditFilters & BaseFilters & {
    assigned: boolean | undefined
}

export const deviceFiltersInitialState: DeviceFilters = {
    search: "",
    active: undefined,
    creationFrom: undefined,
    creationTo: undefined,
    assigned: undefined
}