import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, CardContent, Container, Grid, Menu, Typography } from "@mui/material";
import { PatientDirectory } from "features/clinician/components/patientDirectory";
import { UserFilters, userFiltersInitialState } from "features/users/models/userFilters";
import { PatientFiltersToolbar } from "features/patient/components/patientFiltersToolbar";
import { TitleCard } from "components/cards/titleCard";
import { PersonAddRounded } from "@mui/icons-material";
import { UserInviteForm } from "features/users/components/userInviteForm";
import { UserType } from "models/lookup/userType";
import { FormikProps } from "formik";
import { InviteUserRequest } from "features/users/api/request/inviteUserRequest";
import { Patient } from "features/patient/models/patient";
import { patientApi } from "features/patient/api/patientApi";
import { Pagination, paginationInitialState } from "models/table/pagination";
import { CreatePatientRequest } from "features/patient/api/request/createPatientRequest";
import { PatientCreationForm } from "features/patient/components/patientCreationForm";
import snackbar from "app/helpers/snackbar";


const Patients: React.FC = () => {

    const [patients, setPatients] = useState<Patient[]>([])
    const [filters, setFilters] = useState<UserFilters>(userFiltersInitialState);
    const [pagination, setPagination] = useState<Pagination>(paginationInitialState);
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const open = Boolean(anchor);

    const formik = useRef<FormikProps<any>>(null);

    useEffect(() => {
        patientApi.getPatients(filters, pagination).then((data) => {
            setPatients(data)
        })
    }, [filters, pagination])

    const onPatientCreate = useCallback((request: CreatePatientRequest) => {
        patientApi.createPatient(request).then((response) => {
            setPatients(prevState => [...prevState, response])
        }).catch((e) => snackbar.error(e.response.data.message));
    }, [])

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);

    return (
        <Container maxWidth={'lg'}>
            <TitleCard
                title={"Patient Directory"}
                toolbar={
                    <Button onClick={handleClick} color={"info"} startIcon={<PersonAddRounded/>}>Create Patient</Button>
                }
            >
                <CardContent>
                    <PatientFiltersToolbar/>
                </CardContent>
                <PatientDirectory
                    patients={patients}
                    filters={filters}
                    pagination={pagination}
                />
            </TitleCard>
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant={"menu"}
            >
                <Grid container spacing={2} sx={{ p: 2, maxWidth: "350px" }}>
                    <Grid item>
                        <Typography variant={'h6'}>Create Patient</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PatientCreationForm
                            formik={formik}
                            onSubmit={onPatientCreate}
                        />
                    </Grid>
                    <Grid container item justifyContent={"flex-end"} spacing={2}>
                        <Grid item>
                            <Button color={"error"} onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button color={"success"} onClick={() => formik.current?.submitForm()}>Send</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Menu>
        </Container>
    )
}
export { Patients }