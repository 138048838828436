import { User } from "features/users/models/user";

export type Customer = {
    id: string,
    name: string,
    billingEmail: string,
    users: User[],
    active: boolean
}

export const customerInitialState: Customer = {
    id: "",
    name: "",
    billingEmail: "",
    users: [],
    active: true,
}