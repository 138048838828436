import React from 'react';
import { Container, Typography } from "@mui/material";

const TechnicianDashboard: React.FC = () => {
    return (
        <Container maxWidth={'xl'}>
            <Typography>Mems</Typography>
        </Container>
    )
}
export { TechnicianDashboard }