import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Box, Card, Paper, PaperProps } from "@mui/material";
import { getCardStyling } from "components/layouts/sortableCardsStyling";
import { DragHandleRounded, DragIndicatorRounded } from "@mui/icons-material";

export interface SortableCardsProps {
    items: any[];
    updateItems: (items: any[], result: DropResult) => void;
    renderCardContents: (item: any) => ReactNode;
    PaperProps?: PaperProps;
}

const SortableCards: React.FC<SortableCardsProps> = ({ items, updateItems, renderCardContents, PaperProps }) => {

    const onDragEnd = useCallback(async (result: DropResult) => {

        const { destination, source } = result;

        if (!destination)
            return;

        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;

        const newItems = reorderItems(source.index, destination.index, items);
        updateItems(newItems, result)

    }, [updateItems, items])

    const reorderItems = useCallback((source: number, destination: number, list: any[]) => {
        const item = { ...list[source] }
        list.splice(source, 1)
        list.splice(destination, 0, item);
        return list;
    }, [])

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"droppable"}>
                {(provided, snapshot) => (
                    <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {items.map((item, index) => (
                            <Draggable key={`draggable-${index}`} draggableId={`draggable-${index}`} index={index}>
                                {(provided, snapshot) => (
                                    <Paper
                                        {...PaperProps}
                                        ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}
                                        style={getCardStyling(snapshot.isDragging, provided.draggableProps.style)}
                                        sx={{ ...PaperProps?.sx, position: "relative" }}
                                        variant={"outlined"}
                                    >
                                        {renderCardContents(item)}
                                        <DragIndicatorRounded color={"action"} sx={{
                                            position: "absolute",
                                            height: "100%",
                                            zIndex: 10,
                                            right: 5,
                                        }}/>
                                    </Paper>
                                )}
                            </Draggable>

                        ))}
                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    )
}
export { SortableCards }