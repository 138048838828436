import React from 'react';
import { Scenario } from "features/programme/models/scenario";
import { Box, Card, CardContent, CardHeader, Chip, Divider, Grid, Typography } from "@mui/material";
import { ScenarioDetails } from "features/scenario/components/scenarioDetails";

export interface ScenarioViewProps {
    scenario: Scenario;
}

const ScenarioView: React.FC<ScenarioViewProps> = ({scenario}) => {
    return (
        <Card sx={{width: "100%", height: "100%"}}>
            <Grid container>
                <Grid item xs={12}>
                    <Box p={2} width={"100%"}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item>
                                <Typography variant={"h6"}>{scenario.name}</Typography>
                            </Grid>
                            <Grid item>
                                <Chip label={`Template: ${scenario.template.name}`} color={"primary"}/>
                            </Grid>
                        </Grid>
                        
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item>
                    <CardContent></CardContent>
                </Grid>
            </Grid>
        </Card>
    )
}
export { ScenarioView }