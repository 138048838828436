export type CreateTemplateRequest = {
    name: string,
    description: string,
    configuration: string
}

export const createTemplateRequestInitialState: CreateTemplateRequest = {
    name: "",
    description: "",
    configuration: ""
}