import axios from "axios";

import { msalApi } from "features/authentication/api/msalApi";

const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { "Content-Type": "application/json" }
});

axiosApi.interceptors.request.use((request) => appendAuthHeaders(request));

const appendAuthHeaders = async (request : any) => {
    if (isAuthEnabled(request)) {
        const username = localStorage.getItem("username");
        
        if(username === null)
            // TODO: Error Message
            return;
        
        const tokenResponse = await msalApi.getSilentToken(username);
        request.headers.authorization = "Bearer " + tokenResponse.accessToken
    }

    return request;
};

const isAuthEnabled = (config : any = {}) => {
    return config.headers.disableHandler === undefined;
};

export { axiosApi }