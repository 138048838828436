import React, { RefObject, useEffect, useState } from 'react';
import { Formik, FormikProps } from "formik";
import { Grid, MenuItem } from "@mui/material";
import { TextInput } from "components/input/textInput";
import { nameof } from "app/helpers/stringHelper";
import { createScenarioValidation } from "features/programme/models/createScenarioValidation";
import { SelectInput } from "components/input/selectInput";
import { ScenarioTemplate } from "features/programme/models/scenarioTemplate";
import { scenarioApi } from "features/scenario/api/scenarioApi";
import { CreateScenarioRequest } from "features/scenario/api/request/createScenarioRequest";

export interface CreateScenarioFormProps {
    initialValues: CreateScenarioRequest;
    onSubmit: (values: CreateScenarioRequest) => void;
    formik: RefObject<FormikProps<CreateScenarioRequest>>;
}

const CreateScenarioForm: React.FC<CreateScenarioFormProps> = ({ initialValues, onSubmit, formik }) => {
    
    const [templates, setTemplates] = useState<ScenarioTemplate[]>([])
    
    useEffect(() => {
        scenarioApi.getScenarioTemplates().then((response) => setTemplates(response))
    }, [])
    
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formik}
            validationSchema={createScenarioValidation}
            enableReinitialize
        >
            {({ values, handleChange, setFieldValue, touched, errors }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                name={nameof<CreateScenarioRequest>("name")}
                                label={"Name"}
                                value={values.name}
                                onChange={handleChange}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                placeholder={"Title of scenario"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                name={nameof<CreateScenarioRequest>("description")}
                                label={"Description"}
                                value={values.description}
                                onChange={handleChange}
                                multiline
                                rows={3}
                                error={touched.description && Boolean(errors.description)}
                                helperText={touched.description && errors.description}
                                placeholder={"An example of the scenario including any information on difficulty."}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectInput 
                                name={nameof<CreateScenarioRequest>("templateId")} 
                                label={"Template"}
                                value={values.templateId} 
                                onChange={handleChange}
                                placeholder={"Select Scenario Template"}
                            >
                                { templates.map((template, index) => (
                                    <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                                ))}
                            </SelectInput>
                        </Grid>
                    </Grid>
                )
            }}
        </Formik>
    )
}
export { CreateScenarioForm }