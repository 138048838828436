import { DeviceType } from "features/device/models/deviceType";

export type RegisterDeviceRequest = {
    hardwareId: string,
    friendlyName: string,
    type: DeviceType
}

export const registerDeviceInitialState: RegisterDeviceRequest = {
    hardwareId: "",
    friendlyName: "",
    type: DeviceType.Unknown
}