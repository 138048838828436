import { User, userInitialState } from "features/users/models/user";
import { Clinician } from "features/clinician/models/clinician";
import { Device, deviceInitialState } from "features/device/models/device";
import { Programme, programmeInitialState } from "features/programme/models/programme";

export type Patient = {
    id: string,
    identifier: string,
    deviceId: string | null,
    device: Device | null,
    programmeId: string | null,
    programme: Programme | null,
    assignedClinicians: Clinician[]
}

export const patientInitialState: Patient = {
    id: "",
    identifier: "",
    deviceId: null,
    device: null,
    programmeId: null,
    programme: null,
    assignedClinicians: []
}