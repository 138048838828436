import React, { useEffect, useState } from 'react';
import { Props } from "models/interfaces/props";
import { Box } from "@mui/material";
import { NavDrawer } from "components/navigation/navDrawer";
import { useUserContext } from "app/contexts/userContext";
import { UserType } from "models/lookup/userType";
import { LoadingLayout } from "app/layouts/loadingLayout";

const AdminLayout: React.FC<Props> = ({ children }) => {

    const [loading, setLoading] = useState<boolean>(true)

    const { user } = useUserContext();

    useEffect(() => {
        setLoading(user !== null && user?.type !== UserType.Admin)
    }, [user])

    return (
        <LoadingLayout loading={loading}>
            <Box display={"flex"} flexGrow={1}>
                <NavDrawer/>
                <Box component={"main"} flexGrow={1} p={3} display={"inline-flex"}>
                    {children}
                </Box>
            </Box>
        </LoadingLayout>
    )
}
export { AdminLayout }