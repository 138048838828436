import React from 'react';
import { Props } from "models/interfaces/props";
import { Box, Grid, Typography } from "@mui/material";

import ChooseImage from "graphics/illustrations/choose.svg"

export interface ContentFlaggerProps extends Props {
    value: any | null | undefined;
    text?: string;
}

const ContentFlagger: React.FC<ContentFlaggerProps> = ({value, text, children}) => {
    return (
        <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            { value ? children :
                <Grid container justifyContent={"center"} spacing={5}>
                    <Grid item xs={4}>
                        <img src={ChooseImage} width={"100%"} alt={"Choose Item"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align={"center"} color={"textSecondary"} variant={"h5"}>{text}</Typography>
                    </Grid>
                </Grid>
                
            }
        </Box>
    )
}
export { ContentFlagger }