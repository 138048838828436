import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { patientDirectoryColumns } from "features/clinician/models/patientDirectoryColumns";
import { UserType } from "models/lookup/userType";
import { flatten } from "app/helpers/objectHelper";
import { Patient } from "features/patient/models/patient";
import { UserFilters } from "features/users/models/userFilters";
import { patientApi } from "features/patient/api/patientApi";
import { Pagination, paginationInitialState } from "models/table/pagination";
import { nameof } from "app/helpers/stringHelper";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BaseFilters } from "models/filters/baseFilters";
import { customerDirectoryColumns } from "features/customer/models/customerDirectoryColumns";
import { Customer } from '../models/customer';

export type CustomerDirectoryProps = {
    customers: Customer[];
    filters: BaseFilters;
    pagination: Pagination;
}

const CustomerDirectory: React.FC<CustomerDirectoryProps> = ({customers, filters, pagination}) => {
    
    const navigate = useNavigate();
    
    const viewCustomer = useCallback((customerId: string) => () => {
        navigate(`/admin/customers/${customerId}`)
    }, [navigate])
    
    const viewProfile: GridColDef = {
        headerName: "", field: nameof<Patient>("id"),
        align: "center",
        renderCell: (params) =>
            <IconButton color={"primary"} onClick={viewCustomer(params.value)}><ArrowForwardIosRounded/></IconButton>
    } 
    
    return (
        <DataGrid
            autoHeight
            columns={[...customerDirectoryColumns, viewProfile]}
            rows={customers.map((p) => flatten(p))}
            disableColumnMenu
            pagination
            paginationModel={pagination}
        />
    )
}
export { CustomerDirectory }