import React, { RefObject } from 'react';
import { Formik, FormikProps } from "formik";
import {
    InviteUserRequest,
    unclaimedUserRequestInitialState
} from "features/users/api/request/inviteUserRequest";
import { Grid, MenuItem, Switch, TextField } from "@mui/material";
import { nameof } from "app/helpers/stringHelper";
import { userInviteValidation } from "features/users/models/userInviteValidation";
import { UserType } from "models/lookup/userType";
import { TextInput } from "components/input/textInput";
import { Customer, customerInitialState } from "features/customer/models/customer";
import { SelectInput } from "components/input/selectInput";
import { createCustomerValidation } from "features/customer/models/createCustomerValidation";
import { CreateCustomerRequest } from "features/customer/api/requests/createCustomerRequest";

export interface CustomerInviteFormProps {
    onSubmit: (request: CreateCustomerRequest) => void;
    formik: RefObject<FormikProps<CreateCustomerRequest>>;
}

const CustomerInviteForm: React.FC<CustomerInviteFormProps> = ({ onSubmit, formik }) => {

    return (
        <Formik
            initialValues={customerInitialState}
            onSubmit={onSubmit}
            validationSchema={createCustomerValidation}
            innerRef={formik}
        >
            {({ values, handleChange, setFieldValue, errors, touched }) => (
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextInput name={nameof<CreateCustomerRequest>("name")}
                                   label={"Name"}
                                   value={values.name}
                                   onChange={handleChange}
                                   error={touched.name && Boolean(errors.name)}
                                   helperText={touched.name && errors.name}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput name={nameof<CreateCustomerRequest>("active")}
                                     label={"Active"}
                                     onChange={handleChange}
                                     value={values.active}
                        >
                            <MenuItem value={true as any}>True</MenuItem>
                            <MenuItem value={false as any}>False</MenuItem>
                        </SelectInput>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput name={nameof<CreateCustomerRequest>("billingEmail")}
                                   label={"Billing Email"}
                                   value={values.billingEmail}
                                   onChange={handleChange}
                                   error={touched.billingEmail && Boolean(errors.billingEmail)}
                                   helperText={touched.billingEmail && errors.billingEmail}
                        />
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}
export { CustomerInviteForm }