import React, { ReactElement } from 'react';
import { Card, CardContent, Grid, Typography } from "@mui/material";

export interface IconCardProps {
    icon: ReactElement;
    title: string;
    subtitle: string;
    toolbar?: ReactElement;
    variant?: "elevation" | "outlined" | undefined;
}

const IconCard: React.FC<IconCardProps> = ({ icon, title, subtitle, toolbar, variant = "elevation" }) => {
    return (
        <Card sx={{p: 2, height: "100%"}} variant={variant}>
            <Grid container justifyContent={"center"} spacing={2} alignItems={"center"}>
                <Grid item display={"inline-flex"}>
                    {icon}
                </Grid>
                <Grid container item xs>
                    <Grid item xs>
                        <Typography variant={"h5"} color={"textPrimary"}>{title}</Typography>
                    </Grid>
                    { toolbar &&
                        <Grid item>
                            {toolbar}
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant={"body1"} color={"textSecondary"}>{subtitle}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}
export { IconCard }