import React, { useCallback, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ArrowForwardOutlined, ArrowRightAlt, ArrowRightAltRounded } from "@mui/icons-material";

export interface DatePickerRangeProps {
    from: Date | undefined;
    onFromChange: (from: Date | undefined) => void;
    fromHelperText?: any;
    fromError?: boolean;
    to: Date | undefined
    onToChange: (from: Date | undefined) => void;
    toHelperText?: any;
    toError?: boolean;
}

const DatePickerRange: React.FC<DatePickerRangeProps> = ({
                                                             from,
                                                             onFromChange,
                                                             fromHelperText,
                                                             fromError,
                                                             to,
                                                             onToChange,
                                                             toHelperText,
                                                             toError
                                                         }) => {

    const handleFromChange = useCallback((value: Date | undefined) => {
        onFromChange(value)
    }, [onFromChange])

    const handleToChange = useCallback((value: Date | undefined) => {
        onToChange(value)
    }, [onToChange])

    return (
        <Box>
            <Grid container spacing={2} alignItems={"flex-end"}>
                <Grid item xs>
                    <DatePicker
                        label="From"
                        value={from}
                        // @ts-ignore
                        onChange={handleFromChange}
                        renderInput={(params: any) =>
                            <TextField {...params}
                                       variant={"standard"}
                                       size={"small"}
                                       InputLabelProps={{ shrink: true }}
                                       InputProps={{
                                           ...params.InputProps,
                                           disableUnderline: true
                                       }}
                                       helperText={fromHelperText}
                                       error={fromError}
                            />}
                        inputFormat={"dd/MM/yyyy"}
                    />
                </Grid>
                <Grid item>
                    <ArrowForwardOutlined color={"action"}/>
                </Grid>
                <Grid item xs>
                    <DatePicker
                        label="To"
                        value={to}
                        // @ts-ignore
                        onChange={handleToChange}
                        renderInput={(params: any) =>
                            <TextField {...params}
                                       variant={"standard"}
                                       size={"small"}
                                       InputLabelProps={{ shrink: true }}
                                       InputProps={{
                                           ...params.InputProps,
                                           disableUnderline: true
                                       }}
                                       helperText={toHelperText}
                                       error={toError}
                            />}
                        inputFormat="dd/MM/yyyy"
                    />
                </Grid>
            </Grid>
        </Box>

    )
}
export { DatePickerRange }