import React, { useCallback, useState } from 'react';
import { Button, Card, CardContent, Grid, InputAdornment, Menu, Modal, TextField, Typography } from "@mui/material";
import { AddRounded, FilterAltRounded, SearchRounded } from "@mui/icons-material";
import { PatientFiltersForm } from "features/patient/components/patientFiltersForm";
import { deviceFiltersInitialState } from '../models/deviceFilters';
import { RegisterDeviceForm } from "features/device/components/registerDeviceForm";
import { TextInput } from "components/input/textInput";

export type PatientFiltersToolbarProps = {}

const DeviceFiltersToolbar: React.FC<PatientFiltersToolbarProps> = () => {

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const open = Boolean(anchor);

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid container item justifyContent={"space-between"}>
                    <Grid item>
                        <Button color={"inherit"} size={"large"} onClick={handleClick}
                                startIcon={<FilterAltRounded/>}>Filters</Button>
                    </Grid>
                    <Grid item>
                        <TextInput
                            placeholder={"Search"}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                variant={"menu"}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <CardContent>
                            <Grid container item justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <Typography>Filters</Typography>
                                </Grid>
                                <Grid container item xs justifyContent={"flex-end"}>
                                    <Grid item>
                                        <Button color={"warning"}>Clear</Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button color={"success"}>Apply</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <PatientFiltersForm initialValues={deviceFiltersInitialState} onSubmit={handleSubmit}/>*/}
                    {/*</Grid>*/}
                </Grid>
            </Menu>
        </>

    )
}
export { DeviceFiltersToolbar }