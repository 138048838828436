import { GridColDef } from "@mui/x-data-grid";
import { nameof } from "app/helpers/stringHelper";
import { Checkbox } from "@mui/material";
import { Device } from "features/device/models/device";
import { DeviceType } from "features/device/models/deviceType";

export const deviceDirectoryColumns: GridColDef[] = [
    {
        headerName: "Friendly Name",
        field: nameof<Device>("friendlyName"),
        flex: 0.25,
    },
    {
        headerName: "Hardware ID",
        field: nameof<Device>("hardwareId"),
        flex: 0.25
    },
    {
        headerName: "Type",
        field: nameof<Device>("type"),
        flex: 0.15,
        valueFormatter: (params: any) => DeviceType[params.value]
    },
    {
        headerName: "Active",
        width: 100,
        field: nameof<Device>("active"),
        renderCell: (params) => <Checkbox checked={params.value} disabled/>,
        align: "center",
        headerAlign: "center"
    },
    {
        headerName: "Assigned",
        width: 100,
        field: nameof<Device>("assigned"),
        renderCell: (params) => <Checkbox checked={params.value} disabled/>,
        align: "center",
        headerAlign: "center"
    }
]