import { AuditFilters } from "models/audit/auditFilters";
import { BaseFilters } from "models/filters/baseFilters";

export type UserFilters = AuditFilters & BaseFilters & {
    claimed: boolean | undefined,
}

export const userFiltersInitialState: UserFilters = {
    search: "",
    active: undefined,
    claimed: undefined,
    creationFrom: undefined,
    creationTo: undefined
}