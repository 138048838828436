import React, { ReactNode } from 'react';
import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import { TextInput } from "components/input/textInput";
import { Props } from "models/interfaces/props";
import { BootstrapInput } from "components/overrides/bootstrapInput";

export interface SelectInputProps extends Props {
    name?: string;
    label?: string;
    value?: any;
    onChange?: (event: SelectChangeEvent, child: ReactNode) => void;
    required?: boolean;
    error?: boolean;
    helperText?: string | false;
    placeholder?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({
                                                     name,
                                                     label,
                                                     value,
                                                     onChange,
                                                     required,
                                                     error,
                                                     helperText,
                                                     children,
                                                     placeholder
                                                 }) => {
    return (
        <FormControl variant="standard">
            {label &&
                <InputLabel shrink sx={{ fontSize: "1.3rem" }} error={error}>
                    {label} {required && "*"}
                </InputLabel>
            }
            <Select
                name={name}
                labelId={`${label}-id`}
                value={value}
                onChange={onChange}
                input={<BootstrapInput required={required} placeholder={placeholder}/>}
                error={error}
                placeholder={placeholder}
                displayEmpty
            >
                {children}
            </Select>
            { helperText &&
                <FormHelperText error={error}>
                    {helperText}
                </FormHelperText>
            }
        </FormControl>
    )
}
export { SelectInput }