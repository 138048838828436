import { Grid, Stack, Typography } from '@mui/material';
import { metricApi } from 'features/metrics/api/metricApi';
import { MetricFilters, metricFiltersInitialState } from 'features/metrics/models/metricFilters';
import React, { useCallback, useEffect, useState } from 'react';
import { Patient } from '../models/patient';
import { DatePickerRange } from 'components/input/datePickerRange';
import { Session } from "features/metrics/models/session";
import { nameof } from "app/helpers/stringHelper";
import { ChartsGrid, LineChart, LineSeriesType } from "@mui/x-charts";
import _ from "lodash";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import {MetricCategory} from "../../metrics/models/metricCategory";

export interface PatientStatisticsProps {
    patient: Patient;
}

const PatientStatistics: React.FC<PatientStatisticsProps> = ({ patient }) => {

    const [sessions, setSessions] = useState<Session[]>([])
    const [filters, setFilters] = useState<MetricFilters>(metricFiltersInitialState)
    const [xAxis, setXAxis] = useState<string[]>([])
    const [series, setSeries] = useState<MakeOptional<LineSeriesType, "type">[]>([])

    useEffect(() => {
        if (patient.id) {
            metricApi.getMetricsByPatient(patient.id, filters).then(setSessions)
        }
    }, [patient, filters])

    useEffect(() => {
        const longestSession = _(sessions)
            .map(s => ({
                ...s, 
                metrics: s.metrics.filter(m => m.category === MetricCategory.PerformanceBenchmark)
            }))
            .maxBy((session) => session.metrics.length)
        
        if (longestSession !== undefined) {
            setXAxis(longestSession.metrics.filter(m => m.groups["Type"] === "Duration").map(m => m.context["Name"]))

            setSeries(sessions.map(session => ({
                label: new Date(session.creationDate).toDateString(),
                data: session.metrics.filter(m => m.groups["Type"] === "Duration").map(m => parseFloat(m.context["Duration"])),
                curve: "catmullRom",
                type: 'line',
                disableHighlight: true,
                showMark: false
            })))
        }
    }, [sessions])

    const handleDateRangeChange = useCallback((field: string) => (date: Date | undefined) => {
        setFilters(prevState => ({
            ...prevState,
            [field]: date
        }))
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item container spacing={2} alignItems={"center"}>
                <Grid item>
                    <Typography variant={'h6'} color={"textPrimary"}>Patient Progression Statistics</Typography>
                    <Typography variant={'body1'} color={"textSecondary"}>{patient.identifier}'s recovery
                        progress</Typography>
                </Grid>
                <Grid container item xs justifyContent={"flex-end"}>
                    <Grid item>
                        <DatePickerRange
                            from={filters.createdAfter}
                            to={filters.createdBefore}
                            onFromChange={handleDateRangeChange(nameof<MetricFilters>("createdAfter"))}
                            onToChange={handleDateRangeChange(nameof<MetricFilters>("createdBefore"))}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item>
                <Grid item xs={12}>
                    <Stack sx={{ width: "100%" }}>
                        <LineChart
                            series={series}
                            xAxis={[{
                                data: xAxis.map((item, index) => ({
                                    id: index, 
                                    name: item
                                })),
                                valueFormatter: (v) => v.name,
                                dataKey: "name",
                                scaleType: 'point',
                                disableLine: true,
                                disableTicks: true,
                                tickLabelInterval: () => false
                            }]}
                            yAxis={[{
                                disableLine: true,
                                disableTicks: true,
                                valueFormatter: (v => `${v}s`)
                            }]}
                            height={500}
                        >
                            <ChartsGrid horizontal/>
                        </LineChart>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )
}
export { PatientStatistics }