import React from 'react';
import { Customer } from "features/customer/models/customer";
import { Card, Grid } from "@mui/material";
import { IconCard } from "components/cards/iconCard";
import {
    AccountCircleRounded,
    AccountCircleTwoTone,
    BuildCircleRounded, BuildCircleTwoTone,
    MasksTwoTone, PsychologyAltTwoTone,
    PsychologyTwoTone
} from "@mui/icons-material";

export interface CustomerOverviewProps {
    customer: Customer;
}

const CustomerOverview: React.FC<CustomerOverviewProps> = ({customer}) => {
    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
                <IconCard
                    icon={<AccountCircleTwoTone fontSize={"large"} color={"primary"}/>}
                    title={customer.users.length.toString()}
                    subtitle={"Total Users"}
                    variant={"outlined"}
                />
            </Grid>
            <Grid item  md={3}>
                <IconCard
                    icon={<MasksTwoTone fontSize={"large"} color={"info"}/>}
                    title={customer.users.length.toString()}
                    subtitle={"Total Clinicians"}
                    variant={"outlined"}
                />
            </Grid>
            <Grid item md={3}>
                <IconCard
                    icon={<PsychologyAltTwoTone fontSize={"large"} color={"warning"}/>}
                    title={customer.users.length.toString()}
                    subtitle={"Total Patients"}
                    variant={"outlined"}
                />
            </Grid>
            <Grid item md={3}>
                <IconCard
                    icon={<BuildCircleTwoTone fontSize={"large"} color={"secondary"}/>}
                    title={customer.users.length.toString()}
                    subtitle={"Total Technicians"}
                    variant={"outlined"}
                />
            </Grid>
        </Grid>
    )
}
export { CustomerOverview }