import { axiosApi } from "app/axios/axios";
import { Pagination } from "models/table/pagination";
import { UrlBuilder } from "app/builders/urlBuilder";
import { DeviceFilters } from "features/device/models/deviceFilters";
import { Device } from "features/device/models/device";
import { RegisterDeviceRequest } from "features/device/models/registerDeviceRequest";
import axios, {AxiosResponse} from "axios";
import {MXRDevice} from "../models/mxrDevice";

class DeviceApi {
    
    private urlBuilder: UrlBuilder;
    
    constructor() {
        this.urlBuilder = new UrlBuilder("api/device")
    }

    public async getDevices(filters: DeviceFilters, pagination: Pagination): Promise<Device[]> {
        
        const url = this.urlBuilder
            .withRoute("All")
            .withPagination(pagination)
            .withDeviceFilters(filters)
            .build();
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async getManageXRDevices(): Promise<MXRDevice[]> {

        const url = this.urlBuilder
            .withRoute("manage-xr/devices/all")
            .build();

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async getManageXRDevice(hardwareId: string): Promise<MXRDevice> {

        const url = this.urlBuilder
            .withRoute("manage-xr/devices")
            .withRoute(hardwareId)
            .build();

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }
    
    public async registerDevice(request: RegisterDeviceRequest): Promise<Device> {
        
        const url = this.urlBuilder
            .build();
        
        return await axiosApi.post(url, JSON.stringify(request)).then((response) =>{
            return response.data;
        }).catch((e) => {
            throw e;
        })
    }
    
    public async deleteDevice(deviceId: string): Promise<void> {
        
        const url = this.urlBuilder
            .withRoute(deviceId)
            .build()
        
        return await axiosApi.delete(url).then(() => {
            return;
        }).catch((e) => {
            console.log(e)
            return;
        })
        
    }
    
    public async validateHardwareId(hardwareId: string): Promise<boolean> {
        
        const url = this.urlBuilder
            .withRoute("ValidHardwareId")
            .withRoute(hardwareId)
            .build();
        
        return await axiosApi.post(url).then(response => {
            return response.data;
        }).catch(e => {
            console.log(e)
            return e;
        })
    }

    public async validateFriendlyName(friendlyName: string): Promise<boolean> {

        const url = this.urlBuilder
            .withRoute("ValidFriendlyName")
            .withRoute(friendlyName)
            .build();

        return await axiosApi.post(url).then(response => {
            return response.data;
        }).catch(e => {
            console.log(e)
            return e;
        })
    }
    
    public async checkIn(deviceId: string): Promise<AxiosResponse> {

        const url = this.urlBuilder
            .withRoute(deviceId)
            .withRoute("check-in")
            .build();
        
        return await axiosApi.post(url).then(response => {
            return response;
        }).catch(e => {
            throw e;
        })
    }

    public async checkOut(deviceId: string): Promise<AxiosResponse> {

        const url = this.urlBuilder
            .withRoute(deviceId)
            .withRoute("check-out")
            .build();

        return await axiosApi.post(url).then(response => {
            return response;
        }).catch(e => {
            throw e;
        })
    }
}

const deviceApi = new DeviceApi();
export { deviceApi }