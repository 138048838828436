import { GridColDef } from "@mui/x-data-grid";
import { User } from "features/users/models/user";
import { getInitials, nameof } from "app/helpers/stringHelper";
import { Avatar, Checkbox } from "@mui/material";
import { CheckRounded, CloseRounded } from "@mui/icons-material";
import { Patient } from "features/patient/models/patient";
import { UserType } from "models/lookup/userType";

export const userDirectoryColumns: GridColDef[] = [
    {
        headerName: "",
        field: nameof<User>("id"),
        renderCell: (params) => 
            <Avatar variant={"rounded"}>{getInitials([params.row["firstName"], params.row["surname"]])}</Avatar>,
        align: "center",
        width: 70
    },
    {
        headerName: "First Name",
        field: nameof<User>("firstName"),
        flex: 0.25,
    },
    {
        headerName: "Surname",
        field: nameof<User>("surname"),
        flex: 0.25
    },
    {
        headerName: "Email",
        field: nameof<User>("email"),
        flex: 0.25
    },
    {
        headerName: "Type",
        field: nameof<User>("type"),
        valueFormatter: (params: any) => UserType[params.value],
        width: 150
    },
    {
        headerName: "Claimed",
        field: nameof<User>("claimed"),
        width: 100,
        renderCell: (params) => (params.value 
            ? <CheckRounded color={"success"}/> 
            : <CloseRounded color={"error"}/>
        ),
        align: "center",
        headerAlign: "center"
    },
    {
        headerName: "Active",
        width: 100,
        field: nameof<User>("active"),
        renderCell: (params) => <Checkbox checked={params.value} disabled/>,
        align: "center",
        headerAlign: "center"
    },
]