import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { UserDirectory } from "features/customer/components/userDirectory";
import { Customer } from "features/customer/models/customer";
import { TitleCard } from "components/cards/titleCard";
import { Pagination, paginationInitialState } from "models/table/pagination";
import { UserFilters, userFiltersInitialState } from "features/users/models/userFilters";
import { Box, Button, Card, CardContent, Divider, Grid, ListItemButton, Menu, Modal, Typography } from "@mui/material";
import { UserType } from "models/lookup/userType";
import { msalInstance } from "features/authentication/config/msal";
import { User } from "features/users/models/user";
import { userApi } from "features/users/api/userApi";
import { TextInput } from "components/input/textInput";
import { SearchRounded } from "@mui/icons-material";

export interface CustomerUsersProps {
    customer: Customer;
    updateCustomer: (customer: Customer) => void;
}

const CustomerUsers: React.FC<CustomerUsersProps> = ({ customer }) => {

    const [pagination, setPagination] = useState<Pagination>(paginationInitialState);
    const [filters, setFilters] = useState<UserFilters>(userFiltersInitialState);
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>(customer.users.map(u => u.id))
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    
    useEffect(() => {
        userApi.getPaginatedUsers(pagination).then((response) => setUsers(response))
    }, [pagination])

    const handleSearch = useCallback((e: ChangeEvent) => {
        setFilters(prevState => {
            return ({
                ...prevState,
                // @ts-ignore
                search: e.target.value
            })
        })
    }, [])

    const toggleModal = useCallback((toggle: boolean) => () => {
        setModalVisible(toggle)
    }, [])
    
    return (
        <TitleCard
            title={"Users"}
            toolbar={
                <Button onClick={toggleModal(true)}>Link User to Customer</Button>
            }>
            <UserDirectory
                users={customer.users}
                filters={filters}
                pagination={pagination}
            />
            <Modal
                open={modalVisible}
            >
                <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <TitleCard
                        title={`Link Users to ${customer.name}`}
                        sx={{ width: "70%", maxHeight: "70%" }}
                        toolbar={<TextInput
                            placeholder={"Type to search"}
                            value={filters.search}
                            onChange={handleSearch}
                        />}
                    >
                        <UserDirectory
                            users={users}
                            filters={filters}
                            pagination={pagination}
                            selected={selectedUsers}
                            updateSelected={setSelectedUsers}
                        />
                        <CardContent>
                            <Grid container spacing={1} justifyContent={"flex-end"}>
                                <Grid item>
                                    <Button color={"error"} onClick={toggleModal(false)}>Cancel</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant={"contained"} color={"success"}>Save Changes</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </TitleCard>
                </Box>
            </Modal>
        </TitleCard>
    )
}
export { CustomerUsers }