import React, { ChangeEventHandler, ReactNode } from 'react';
import {
    alpha,
    FormControl,
    FormHelperText,
    InputBase,
    InputBaseComponentProps,
    InputLabel,
    InputProps,
    styled
} from "@mui/material";
import { BootstrapInput } from "components/overrides/bootstrapInput";

export interface TextInputProps {
    name?: string;
    label?: string;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    placeholder?: string;
    required?: boolean;
    InputProps?: InputBaseComponentProps;
    error?: boolean;
    helperText?: string | false;
    multiline?: boolean;
    rows?: number;
}

const TextInput: React.FC<TextInputProps> = ({
                                                 name,
                                                 label,
                                                 placeholder,
                                                 value,
                                                 onChange,
                                                 required,
                                                 InputProps,
                                                 error,
                                                 helperText,
                                                 multiline,
                                                 rows,
                                             }) => {
    return (
        <FormControl variant="standard">
            {label &&
                <InputLabel shrink sx={{ fontSize: "1.3rem" }} error={error}>
                    {label} {required && "*"}
                </InputLabel>
            }
            <BootstrapInput
                name={name}
                size={"small"}
                placeholder={placeholder}
                defaultValue={value}
                onChange={onChange}
                inputProps={InputProps}
                error={error}
                multiline={multiline}
                rows={rows}
            />
            {helperText &&
                <FormHelperText error={error}>
                    {helperText}
                </FormHelperText>
            }
        </FormControl>
    )
}
export { TextInput }