import { axiosApi } from "app/axios/axios";
import { Pagination } from "models/table/pagination";
import { UrlBuilder } from "app/builders/urlBuilder";
import { UserFilters } from "features/users/models/userFilters";
import { Patient, patientInitialState } from "features/patient/models/patient";
import { InviteUserRequest } from "features/users/api/request/inviteUserRequest";
import { CreatePatientRequest } from "features/patient/api/request/createPatientRequest";

class PatientApi {
    
    private urlBuilder: UrlBuilder;
    
    constructor() {
        this.urlBuilder = new UrlBuilder("api/patient")
    }

    public async getPatients(filters: UserFilters, pagination: Pagination): Promise<Patient[]> {
        
        const url = this.urlBuilder
            .withPagination(pagination)
            .withUserFilters(filters)
            .build();
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }
    
    public async getPatient(patientId: string): Promise<Patient> {
        
        const url = this.urlBuilder
            .withRoute(patientId)
            .build();
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            })
        
    }

    public async createPatient(request: CreatePatientRequest): Promise<Patient> {

        const url = this.urlBuilder
            .withRoute("CreatePatient")
            .build();

        return await axiosApi.post(url, JSON.stringify(request))
            .then((response) => response.data)
            .catch(e => { throw e })
    }

    public async assignDevice(patientId: string, deviceId: string): Promise<Patient> {

        const url = this.urlBuilder
            .withRoute("AssignDevice")
            .withRoute(patientId)
            .withRoute(deviceId)
            .build()

        return await axiosApi.post(url).then((response) => {
            return response.data;
        }).catch((e) => console.error(e))
    }

    public async unassignDevice(patientId: string): Promise<Patient> {

        const url = this.urlBuilder
            .withRoute("UnassignDevice")
            .withRoute(patientId)
            .build()

        return await axiosApi.delete(url).then((response) => {
            return response.data;
        }).catch((e) => console.error(e))
    }

    public async assignProgramme(patientId: string, programmeId: string): Promise<Patient> {

        const url = this.urlBuilder
            .withRoute("AssignProgramme")
            .withRoute(patientId)
            .withRoute(programmeId)
            .build()

        return await axiosApi.post(url).then((response) => {
            return response.data;
        }).catch((e) => console.error(e))
    }

    public async unassignProgramme(patientId: string): Promise<Patient> {

        const url = this.urlBuilder
            .withRoute("UnassignProgramme")
            .withRoute(patientId)
            .build()

        return await axiosApi.delete(url).then((response) => {
            return response.data;
        }).catch((e) => console.error(e))
    }
}

const patientApi = new PatientApi();
export { patientApi }