import { axiosApi } from "app/axios/axios";
import { Pagination } from "models/table/pagination";
import { UrlBuilder } from "app/builders/urlBuilder";
import { DeviceFilters } from "features/device/models/deviceFilters";
import { Device } from "features/device/models/device";
import { RegisterDeviceRequest } from "features/device/models/registerDeviceRequest";
import axios from "axios";
import { BaseFilters } from "models/filters/baseFilters";
import { Customer } from "features/customer/models/customer";
import { CreateCustomerRequest } from "features/customer/api/requests/createCustomerRequest";

class CustomerApi {
    
    private urlBuilder: UrlBuilder;
    
    constructor() {
        this.urlBuilder = new UrlBuilder("api/customer")
    }
    
    public async getCustomer(customerId: string): Promise<Customer> {
        
        const url = this.urlBuilder
            .withRoute(customerId)
            .build();
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => e)
        
    }

    public async getCustomers(filters: BaseFilters, pagination: Pagination): Promise<Customer[]> {
        
        const url = this.urlBuilder
            .withBaseFilters(filters)
            .withPagination(pagination)
            .build();
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }
    
    public async createCustomer(request: CreateCustomerRequest): Promise<Customer> {
        
        const url = this.urlBuilder
            .build();
        
        return await axiosApi.post(url, JSON.stringify(request))
            .then((response) => response.data)
            .catch((e) => e)
    }
}

const customerApi = new CustomerApi();
export { customerApi }