import { GridColDef } from "@mui/x-data-grid";
import { nameof } from "app/helpers/stringHelper";
import { Checkbox } from "@mui/material";
import { Customer } from "features/customer/models/customer";

export const customerDirectoryColumns: GridColDef[] = [
    {
        headerName: "Name",
        field: nameof<Customer>("name"),
        flex: 1.0,
    },
    {
        headerName: "Total Users",
        field: nameof<Customer>("users"),
        width: 100,
        valueFormatter: (params: any) => params.length,
        align: "center",
        headerAlign: "center",
    },
    {
        headerName: "Active",
        width: 100,
        field: nameof<Customer>("active"),
        renderCell: (params) => <Checkbox checked={params.value} disabled/>,
        align: "center",
        headerAlign: "center"
    }
]