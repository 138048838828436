import React, { useCallback } from 'react';
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { msalInstance } from "features/authentication/config/msal";

const Landing: React.FC = () => {

    const onLoginClicked = useCallback(() => {
        msalInstance.loginRedirect()
    }, [msalInstance])

    return (
        <Container sx={{ minHeight: "inherit", alignContent: "center", justifyContent: "center" }}>
            <Grid container md={4} m={"0 auto"} justifyContent={"center"} spacing={1}>
                <Grid item>
                    <Typography variant={"h5"}>VIRTUE Portal | Trial Environment</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"body1"}>

                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant={"contained"} onClick={onLoginClicked}>
                        Customer Login
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}
export { Landing }