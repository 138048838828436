import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { patientDirectoryColumns } from "features/clinician/models/patientDirectoryColumns";
import { flatten } from "app/helpers/objectHelper";
import { Patient } from "features/patient/models/patient";
import { patientApi } from "features/patient/api/patientApi";
import { Pagination, paginationInitialState } from "models/table/pagination";
import { nameof } from "app/helpers/stringHelper";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BaseFilters } from "models/filters/baseFilters";
import { Programme } from "features/programme/models/programme";
import { programmeApi } from "features/programme/api/programmeApi";
import { programmeDirectoryColumns } from "features/programme/models/programmeDirectoryColumns";
import { CreateProgrammeRequest } from "features/programme/api/request/createProgrammeRequest";

export type ProgrammeDirectoryProps = {
    programmes: Programme[],
    filters: BaseFilters,
    pagination: Pagination
}

const ProgrammeDirectory: React.FC<ProgrammeDirectoryProps> = ({programmes, filters, pagination}) => {
    
    const navigate = useNavigate();
    
    const onProgrammeClick = useCallback((programmeId: string) => () => {
        navigate(`/clinician/programmes/${programmeId}`)
    }, [navigate])
    
    const viewProgamme: GridColDef = {
        headerName: "", field: nameof<Patient>("id"),
        align: "center",
        renderCell: (params) =>
            <IconButton color={"primary"} onClick={onProgrammeClick(params.value)}><ArrowForwardIosRounded/></IconButton>
    } 
    
    return (
        <DataGrid
            autoHeight
            columns={[...programmeDirectoryColumns, viewProgamme]}
            rows={programmes.map((p) => flatten(p))}
            disableColumnMenu
            pagination
            paginationModel={pagination}
        />
    )
}
export { ProgrammeDirectory }