import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import {
    Button,
    Card,
    CardContent,
    CardHeader, CircularProgress,
    Container,
    Divider,
    Grid, LinearProgress,
    Link, Skeleton,
    ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import {Patient, patientInitialState} from "features/patient/models/patient";
import {patientApi} from "features/patient/api/patientApi";
import {ProfileHeading} from "features/patient/components/profile/profileHeading";
import {CastRounded, ForwardToInboxRounded, PersonAddRounded, PersonRemoveRounded} from "@mui/icons-material";
import {clinicianApi} from "features/clinician/api/clinicianApi";
import {useClinicianContext} from "app/contexts/clinicianContext";
import {ProfileAssignments} from "features/patient/components/profile/profileAssignments";
import {PatientRecentActivity} from "../../features/patient/components/patientRecentActivity";
import {PatientStatistics} from "features/patient/components/patientStatistics";
import {MXRDevice} from "../../features/device/models/mxrDevice";
import {deviceApi} from "../../features/device/api/deviceApi";

const PatientProfile: React.FC = () => {

    const [patient, setPatient] = useState<Patient>(patientInitialState)
    const [device, setDevice] = useState<MXRDevice | null>(null)
    const [checking, setChecking] = useState<boolean>(true);

    const {clinician} = useClinicianContext();
    const {patientId} = useParams();

    useEffect(() => {
        // @ts-ignore
        patientApi.getPatient(patientId).then(response => setPatient(response));

    }, [patientId])

    useEffect(() => {
        if (patient.device) {
            deviceApi.getManageXRDevice(patient.device?.hardwareId)
                .then(device => setDevice(device))
                .finally(() => setChecking(false))
        }
    }, [patient])

    const assignPatient = useCallback(() => {

        if (clinician === undefined)
            return;

        clinicianApi.assignPatient(clinician.id, patient.id).then(() => {
            setPatient(prevState => ({
                ...prevState,
                assignedClinicians: [...prevState.assignedClinicians, clinician]
            }))
        })
    }, [clinician, patient])

    const unassignPatient = useCallback(() => {

        if (clinician === undefined)
            return;

        clinicianApi.unassignPatient(clinician.id, patient.id).then(() => {
            setPatient(prevState => ({
                ...prevState,
                assignedClinicians: prevState.assignedClinicians.filter(ac => ac.id !== clinician.id)
            }))
        })
    }, [clinician, patient])

    const handleDeviceCheck = useCallback((_: React.MouseEvent<HTMLElement>, value: boolean) => {

        if (patient.deviceId === null)
            return;

        setChecking(true);

        (value ? deviceApi.checkOut(patient.deviceId) : deviceApi.checkIn(patient.deviceId))
            .then(() => {
                setDevice(prevState => prevState !== null ? ({
                    ...prevState,
                    pauseUpdates: value
                }): null)
            })
            .finally(() => setChecking(false));
    }, [patient])

    return (
        <Container maxWidth={'lg'}>
            <Card>
                <CardContent>
                    <ProfileHeading
                        name={patient.identifier}
                        toolbar={
                            <Grid container item spacing={2} alignItems={"center"}>
                                <Grid container item xs spacing={2}>
                                    <Grid item>
                                        {patient.assignedClinicians.some(ac => ac.id == clinician?.id) ?
                                            <Button onClick={unassignPatient} size={"small"} color={"error"}
                                                    startIcon={<PersonRemoveRounded/>}>
                                                Unassign yourself from {patient.identifier}
                                            </Button>
                                            :
                                            <Button onClick={assignPatient} size={"small"} color={"success"}
                                                    startIcon={<PersonAddRounded/>}>
                                                Assign yourself to {patient.identifier}
                                            </Button>
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={() => window.open("https://www.oculus.com/casting", "_blank")}
                                                size={"small"} color={"info"} startIcon={<CastRounded/>}>
                                            View Headset Stream
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    {device !== undefined && !checking ?
                                        <ToggleButtonGroup onChange={handleDeviceCheck} exclusive size={"small"}
                                                           value={device?.pauseUpdates ?? null}>
                                            <ToggleButton value={false} color={"info"}>Check In</ToggleButton>
                                            <ToggleButton value={true} color={"info"}>Check Out</ToggleButton>
                                        </ToggleButtonGroup>
                                        :
                                        <LinearProgress sx={{ width:145}} color={"info"} variant={"indeterminate"}/>
                                    }
                                </Grid>
                            </Grid>
                        }
                    />
                </CardContent>
                <Divider/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <ProfileAssignments
                                patient={patient}
                                updatePatient={setPatient}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PatientRecentActivity
                                patient={patient}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PatientStatistics
                                patient={patient}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    )
}
export {PatientProfile}