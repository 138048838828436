import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Patient } from "../models/patient";
import { metricApi } from "../../metrics/api/metricApi";
import { SessionStatistics } from "../../metrics/models/sessionStatistics";
import { formatDistance } from "date-fns";

export interface PatientRecentActivityProps {
    patient: Patient
}

const PatientRecentActivity: React.FC<PatientRecentActivityProps> = ({ patient }) => {

    const [sessionStatistics, setSessionStatistics] = React.useState<SessionStatistics[]>([]);

    useEffect(() => {
        if (patient.id !== "") {
            metricApi.getRecentActivityByPatient(patient.id).then(setSessionStatistics)
        }
    }, [patient])

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={'h6'} color={"textSecondary"}>Recent Activity</Typography>
            </Grid>
            <Grid item xs={12}>
                <List disablePadding>
                    {sessionStatistics.map((stats) => {
                        return (
                            <ListItem disablePadding>
                                <ListItemText 
                                    primary={`Completed ${stats.scenariosComplete} scenarios, totalling ${stats.goalsComplete} goals!`}
                                    secondary={formatDistance(new Date(stats.creationDate), new Date(), { addSuffix: true })}
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
        </Grid>
    )
}
export { PatientRecentActivity }