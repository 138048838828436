import React, { ReactElement } from 'react';
import { Card, CardContent, Grid, SxProps, Typography } from "@mui/material";
import { Props } from "models/interfaces/props";

export interface TitleCardProps extends Props {
    title: string;
    subtitle?: string | undefined;
    toolbar?: ReactElement | undefined;
    height?: string | undefined;
    sx?: SxProps;
}

const TitleCard: React.FC<TitleCardProps> = ({ title, subtitle, toolbar, height = "auto", sx, children }) => {
    return (
        <Card sx={sx}>
            <CardContent>
                <Grid container item justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant={"h5"} color={"textPrimary"}>{title}</Typography>
                        {subtitle &&
                            <Typography variant={"body1"} color={"textSecondary"}>{subtitle}</Typography>
                        }
                    </Grid>
                    { toolbar &&
                        <Grid item>
                            {toolbar}
                        </Grid>
                    }
                </Grid>
            </CardContent>
            {children}
        </Card>
    )
}
export { TitleCard }