import React, { useCallback, useState } from 'react';
import { Formik } from "formik";
import { PatientFilters } from "features/patient/models/patientFilters";
import { Grid, Switch, Typography } from "@mui/material";
import { TitleAccordion } from 'components/layouts/titleAccordion';
import { DatePickerRange } from "components/input/datePickerRange";
import { nameof } from "app/helpers/stringHelper";

export type PatientFiltersFormProps = {
    initialValues: PatientFilters;
    onSubmit: (filters: PatientFilters) => void;
}

const PatientFiltersForm: React.FC<PatientFiltersFormProps> = ({ initialValues, onSubmit }) => {

    const [expanded, setExpanded] = useState<string>("");

    const expandAccordion = useCallback((accordion: string) => () => {
        setExpanded(prevState => prevState !== accordion ? accordion : "")
    }, [])
    
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({ values, touched, errors, handleChange, setFieldValue, submitForm }) => {
                return (
                    <>
                        <TitleAccordion
                            expanded={expanded === "timeframe"}
                            title={"Timeframe"}
                            onChange={expandAccordion("timeframe")}
                            error={touched.creationFrom && Boolean(errors.creationFrom) || touched.creationTo && Boolean(errors.creationTo)}
                            subtitle={touched.creationFrom && Boolean(errors.creationFrom) || touched.creationTo && Boolean(errors.creationTo) ? "Error: Expand to view" : undefined}
                        >
                            <DatePickerRange
                                from={values.creationFrom}
                                to={values.creationTo}
                                onFromChange={(v) => setFieldValue(nameof<PatientFilters>("creationFrom"), v)}
                                onToChange={(v) => setFieldValue(nameof<PatientFilters>("creationTo"), v)}
                                fromError={touched.creationFrom && Boolean(errors.creationFrom)}
                                fromHelperText={touched.creationFrom && errors.creationFrom}
                                toError={touched.creationTo && Boolean(errors.creationTo)}
                                toHelperText={touched.creationTo && errors.creationTo}
                            />
                        </TitleAccordion>
                        <TitleAccordion
                            expanded={expanded === "states"}
                            title={"Account State"}
                            onChange={expandAccordion("states")}
                        >
                            <Grid container>
                                <Grid container item justifyContent={"space-between"} alignItems={"center"}>
                                    <Grid item>
                                        <Typography>Active</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Switch name={nameof<PatientFilters>("active")} checked={values.active} onChange={handleChange}/>
                                    </Grid>
                                </Grid>

                                <Grid container item justifyContent={"space-between"} alignItems={"center"}>
                                    <Grid item>
                                        <Typography>Claimed</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Switch name={nameof<PatientFilters>("claimed")} checked={values.claimed} onChange={handleChange}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TitleAccordion>
                    </>
                )
            }}
        </Formik>
    )
}
export { PatientFiltersForm }