import { Facility } from "features/facility/models/facility";
import { User } from "features/users/models/user";
import { Patient } from "features/patient/models/patient";
import { Scenario } from "features/programme/models/scenario";
import { Audit } from "models/audit/audit";

export type Programme = Audit & {
    id: string,
    name: string,
    description: string,
    patients: Patient[],
    scenarios: Scenario[],
    active: boolean
}

export const programmeInitialState: Programme = {
    id: "",
    name: "",
    description: "",
    scenarios: [],
    patients: [],
    createdBy: "System",
    lastUpdatedBy: "System",
    creationDate: "",
    lastUpdatedDate: "",
    active: true
}