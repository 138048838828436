import * as Yup from "yup";
import { debounce } from "lodash";
import { programmeApi } from "features/programme/api/programmeApi";

export const validateName = debounce(async (value: string | undefined): Promise<boolean> => {
    if(value === undefined)
        return false;
    
    return programmeApi.validateName(value).then(response => response).catch(() => false);
}, 500, { trailing: true})

export const createProgrammeValidation = Yup.object().shape({
    // @ts-ignore
    name: Yup.string().required("Required").test("isNameValid", "Name already exists", async (value) => !await validateName(value)),
    description: Yup.string().required("Required"),
})