import React, { useCallback, useRef, useState } from 'react';
import { Button, Card, CardContent, Container, Grid, Menu, Typography } from "@mui/material";
import { DeviceFilters, deviceFiltersInitialState } from "features/device/models/deviceFilters";
import { DeviceDirectory } from "features/device/components/deviceDirectory";
import { DeviceFiltersToolbar } from "features/device/components/deviceFiltersToolbar";
import { AddRounded } from "@mui/icons-material";
import { RegisterDeviceForm } from "features/device/components/registerDeviceForm";
import { FormikProps } from "formik";
import { RegisterDeviceRequest } from "features/device/models/registerDeviceRequest";
import { deviceApi } from "features/device/api/deviceApi";
import { Device } from "features/device/models/device";
import snackbar from "../../app/helpers/snackbar";

const Devices: React.FC = () => {

    const [devices, setDevices] = useState<Device[]>([])
    const [deviceFilters, setDeviceFilters] = useState<DeviceFilters>(deviceFiltersInitialState);
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const open = Boolean(anchor);

    const formik = useRef<FormikProps<any>>(null);

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);

    const onDeviceRegistered = useCallback((request: RegisterDeviceRequest) => {
        deviceApi.registerDevice(request).then((device) => {
            setDevices(prevState => [...prevState, device])
            handleClose();
        }).catch((e) => snackbar.error(e.response.data.message));
    }, [setDevices])
    
    return (
        <Container maxWidth={'lg'}>
            <Card>
                <CardContent>
                    <Grid container item justifyContent={"space-between"} alignItems={"center"} spacing={2}>
                        <Grid item>
                            <Typography variant={"h4"}>Device Directory</Typography>
                        </Grid>
                        <Grid item>
                            <Button color={"success"} startIcon={<AddRounded/>} onClick={handleClick}>
                                Register New Device
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <DeviceFiltersToolbar/>
                        </Grid>
                    </Grid>
                </CardContent>
                <DeviceDirectory
                    devices={devices}
                    updateDevices={setDevices}
                    filters={deviceFilters}
                />
            </Card>
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant={"menu"}
            >
                <Grid container spacing={2} sx={{ p: 2, maxWidth: "600px" }}>
                    <Grid item>
                        <Typography variant={'h6'}>Register New Device</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <RegisterDeviceForm
                            formik={formik}
                            onSubmit={onDeviceRegistered}/>
                    </Grid>
                    <Grid container item justifyContent={"flex-end"} spacing={2}>
                        <Grid item>
                            <Button color={"error"} onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button color={"success"} onClick={() => formik.current?.submitForm()}>Register</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Menu>
        </Container>
    )
}
export { Devices }