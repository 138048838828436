import React, { Ref } from 'react';
import { Formik, FormikProps } from "formik";
import { registerDeviceInitialState, RegisterDeviceRequest } from "features/device/models/registerDeviceRequest";
import { Grid, MenuItem } from "@mui/material";
import { TextInput } from "components/input/textInput";
import { nameof } from "app/helpers/stringHelper";
import { DeviceType } from "features/device/models/deviceType";
import { SelectInput } from "components/input/selectInput";
import { registerDeviceValidation } from "features/device/models/registerDeviceValidation";

export interface RegisterDeviceFormProps {
    onSubmit: (values: RegisterDeviceRequest) => void;
    formik: Ref<FormikProps<any>>;
}

const RegisterDeviceForm: React.FC<RegisterDeviceFormProps> = ({ onSubmit, formik }) => {
    
    return (
        <Formik
            initialValues={registerDeviceInitialState}
            onSubmit={onSubmit}
            innerRef={formik}
            validationSchema={registerDeviceValidation}
        >
            {({ values, handleChange, touched, errors }) => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            name={nameof<RegisterDeviceRequest>("friendlyName")}
                            label={"Friendly Name"}
                            value={values.friendlyName}
                            onChange={handleChange}
                            required
                            error={touched.friendlyName && Boolean(errors.friendlyName)}
                            helperText={touched.friendlyName && errors.friendlyName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            name={nameof<RegisterDeviceRequest>("type")}
                            label={"Device Type"}
                            value={values.type}
                            onChange={handleChange}
                            required
                            error={touched.type && Boolean(errors.type)}
                            helperText={touched.type && errors.type}
                        >
                            <MenuItem value={DeviceType.Unknown}>Unknown</MenuItem>
                            <MenuItem value={DeviceType.Quest2}>Quest 2</MenuItem>
                        </SelectInput>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            name={nameof<RegisterDeviceRequest>("hardwareId")}
                            label={"Hardware I.D"}
                            value={values.hardwareId}
                            onChange={handleChange}
                            required
                            error={touched.hardwareId && Boolean(errors.hardwareId)}
                            helperText={touched.hardwareId && errors.hardwareId}
                        />
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}
export { RegisterDeviceForm }