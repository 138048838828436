import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Button,
    Card,
    Chip,
    Container,
    Grid,
    Menu, ToggleButton,
    Typography,
} from "@mui/material";
import { Programme, programmeInitialState } from "features/programme/models/programme";
import { programmeApi } from 'features/programme/api/programmeApi';
import { useParams } from "react-router-dom";
import { SortableCards } from "components/layouts/sortableCards";
import { Scenario } from "features/programme/models/scenario";
import { AddRounded } from "@mui/icons-material";
import { FormikProps } from "formik";
import { scenarioApi } from "features/scenario/api/scenarioApi";
import { DropResult } from "react-beautiful-dnd";
import { ScenarioView } from "features/scenario/components/scenarioView";
import { ContentFlagger } from "components/layouts/contentFlagger";
import {
    CreateScenarioRequest,
    createScenarioRequestInitialState
} from "features/scenario/api/request/createScenarioRequest";
import { CreateScenarioForm } from "features/scenario/components/createScenarioForm";

const ProgrammeView: React.FC = () => {

    const [selectedScenario, setSelectedScenario] = useState<Scenario | null>(null)
    const [programme, setProgramme] = useState<Programme>(programmeInitialState)
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const open = Boolean(anchor);

    const { programmeId } = useParams();

    const formik = useRef<FormikProps<CreateScenarioRequest>>(null);

    useEffect(() => {
        // @ts-ignore
        programmeApi.getProgramme(programmeId).then((response) => setProgramme(response))
    }, [programmeId])

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);

    const handleSelect = useCallback((scenario: Scenario) => () => {
        setSelectedScenario(scenario)
    }, [])

    const handleSubmit = useCallback((request: CreateScenarioRequest) => {
        scenarioApi.createScenario(request).then((scenario) => {
            setProgramme({
                ...programme,
                scenarios: [...programme.scenarios, scenario]
            })

            handleClose();
        })
    }, [programme])

    const handleReorder = useCallback(async (scenarios: Scenario[], result: DropResult) => {

        if (result.destination === undefined)
            return;

        const scenarioId = programme.scenarios.find(s => s.order === result.source.index)?.id;

        if (scenarioId === undefined)
            return;

        setProgramme({
            ...programme,
            scenarios: scenarios
        })

        if (result.destination === null)
            return;
        
        return scenarioApi.reorderScenario(scenarioId, result.destination.index).then((response) => {
            setProgramme({
                ...programme,
                scenarios: response
            })

            return response;
        }).catch((e) => {
            return programme.scenarios.sort((a, b) => a.order - b.order);
        })
    }, [programme])

    return (
        <Container maxWidth={false}>
            <Grid container spacing={2} height={"100%"} flexDirection={"column"}>
                <Grid container item>
                    <Grid item xs={12}>
                        <Typography variant={'h5'} color={"textPrimary"}>{programme.name}</Typography>
                        <Typography color={"textSecondary"}>{programme.description}</Typography>
                    </Grid>
                </Grid>
                <Grid container item flexGrow={"1 !important"} alignItems={"stretch"} spacing={2}>
                    <Grid item xs={3}>
                        <Card sx={{ p: 1, height: "100%", width: "100%" }}>
                            <Grid container>
                                <Grid container item justifyContent={"space-between"}>
                                    <Grid item>
                                        <Chip
                                            label={"Scenarios"}
                                            size={"medium"}
                                            color={"default"}
                                            sx={{ mb: 1 }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button variant={"contained"} color={"success"} size={"small"}
                                                onClick={handleClick}><AddRounded/></Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <SortableCards
                                        items={programme.scenarios}
                                        updateItems={handleReorder}
                                        PaperProps={{ sx: { border: "none" } }}
                                        renderCardContents={(scenario: Scenario) => (
                                            <ToggleButton
                                                color={"primary"}
                                                onClick={handleSelect(scenario)}
                                                value={scenario.id}
                                                selected={selectedScenario?.id === scenario.id}
                                                fullWidth
                                            >
                                                <Grid container alignItems={"center"} spacing={2}>
                                                    <Grid item>
                                                        <Typography sx={{ width: 20 }} textAlign={"center"}
                                                                    variant={"body2"}
                                                                    color={"textSecondary"}>{scenario.order + 1}</Typography>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography color={"textPrimary"}
                                                                    variant={"body1"}
                                                                    fontWeight={"bold"}>{scenario.name}</Typography>
                                                        <Typography color={"textSecondary"} sx={{mr: 2}}
                                                                    variant={"body2"}>{scenario.description}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ToggleButton>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={9}>
                        <ContentFlagger
                            value={selectedScenario}
                            text={programme.scenarios.length > 0 ? "Select a Scenario" : "Create a Scenario"}
                        >
                            <ScenarioView
                                scenario={selectedScenario!}
                            />
                        </ContentFlagger>

                    </Grid>
                </Grid>
            </Grid>
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant={"menu"}
            >
                <Grid container sx={{ p: 1, width: "400px" }} spacing={2}>
                    <Grid item xs={12}>
                        <CreateScenarioForm
                            initialValues={{
                                ...createScenarioRequestInitialState,
                                programmeId: programme.id ?? ""
                            }}
                            onSubmit={handleSubmit}
                            formik={formik}
                        />
                    </Grid>
                    <Grid container item justifyContent={"flex-end"}>
                        <Grid item>
                            <Button color={"success"} onClick={() => formik.current?.submitForm()}>Create
                                Scenario</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Menu>
        </Container>
    )
}
export { ProgrammeView }