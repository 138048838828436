import { axiosApi } from "app/axios/axios";
import { Pagination } from "models/table/pagination";
import { UrlBuilder } from "app/builders/urlBuilder";
import { BaseFilters } from "models/filters/baseFilters";
import { Scenario } from "features/programme/models/scenario";
import { ScenarioTemplate } from "features/programme/models/scenarioTemplate";
import { CreateTemplateRequest } from "features/scenario/api/request/createTemplateRequest";
import { CreateScenarioRequest } from "features/scenario/api/request/createScenarioRequest";

class ScenarioApi {

    private urlBuilder: UrlBuilder;

    constructor() {
        this.urlBuilder = new UrlBuilder("api/scenario")
    }

    public async getScenarios(filters: BaseFilters, pagination: Pagination): Promise<Scenario[]> {

        const url = this.urlBuilder
            .withPagination(pagination)
            .withBaseFilters(filters)
            .build();

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async getScenario(scenarioId: string): Promise<Scenario> {

        const url = this.urlBuilder
            .withRoute(scenarioId)
            .build()

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            })
    }

    public async createScenario(request: CreateScenarioRequest): Promise<Scenario> {

        const url = this.urlBuilder.build()

        return await axiosApi.post(url, JSON.stringify(request))
            .then(response => response.data)
            .catch(e => {
                return e;
            })
    }
    
    public async reorderScenario(scenarioId: string, order: number): Promise<Scenario[]> {
        const url = this.urlBuilder
            .withRoute("reorder")
            .withRoute(scenarioId)
            .withRoute(order.toFixed())
            .build()
        
        return await axiosApi.post(url).then(response => response.data).catch(e => {
            return e;
        })
    }

    public async getScenarioTemplates(filters: BaseFilters | undefined = undefined, pagination: Pagination | undefined = undefined): Promise<ScenarioTemplate[]> {

        this.urlBuilder.withRoute("templates")

        if(filters)
            this.urlBuilder.withBaseFilters(filters)
        
        if(pagination)
            this.urlBuilder.withPagination(pagination)
                
        
        const url = this.urlBuilder.build();

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async getScenarioTemplate(scenarioTemplateId: string): Promise<ScenarioTemplate> {

        const url = this.urlBuilder
            .withRoute("template")
            .withRoute(scenarioTemplateId)
            .build();

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async createTemplate(request: CreateTemplateRequest): Promise<ScenarioTemplate> {

        const url = this.urlBuilder
            .withRoute("templates")
            .build();

        return await axiosApi.post(url, request)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async editTemplate(scenarioTemplate: ScenarioTemplate): Promise<ScenarioTemplate> {

        const url = this.urlBuilder
            .withRoute("templates")
            .build();

        return await axiosApi.patch(url, scenarioTemplate)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async deleteTemplate(templateId: string): Promise<void> {

        const url = this.urlBuilder
            .withRoute("templates")
            .withRoute(templateId)
            .build();

        return await axiosApi.delete(url)
            .catch(e => {
                return e;
            });
    }

    public async validateName(name: string): Promise<boolean> {

        const url = this.urlBuilder
            .withRoute("ValidName")
            .withRoute(name)
            .build()

        return await axiosApi.post(url).then(response => response.data)
            .catch(e => {
                return e;
            })
    }
}

const scenarioApi = new ScenarioApi();
export { scenarioApi }