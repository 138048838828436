import { axiosApi } from "app/axios/axios";
import { Pagination } from "models/table/pagination";
import { UrlBuilder } from "app/builders/urlBuilder";
import { UserFilters } from "features/users/models/userFilters";
import { Patient } from "features/patient/models/patient";
import { Clinician } from "features/clinician/models/clinician";
import { Technician } from "features/technician/models/technician";

class TechnicianApi {
    
    private urlBuilder: UrlBuilder;
    
    constructor() {
        this.urlBuilder = new UrlBuilder("api/technician")
    }

    public async getTechnicianFromContext(): Promise<Technician> {

        const url = this.urlBuilder
            .build();

        return await axiosApi.get(url).then(response => {
            return response.data;
        }).catch(e => {
            console.log(e)
            return e;
        })

    }
    
}

const technicianApi = new TechnicianApi();
export { technicianApi }