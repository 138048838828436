import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { SelectInput } from "components/input/selectInput";
import { Programme } from "features/programme/models/programme";
import { Device } from "features/device/models/device";
import { programmeApi } from "features/programme/api/programmeApi";
import { deviceApi } from "features/device/api/deviceApi";
import { baseFiltersInitialState } from "models/filters/baseFilters";
import { paginationInitialState } from "models/table/pagination";
import { DeviceFilters, deviceFiltersInitialState } from "features/device/models/deviceFilters";
import { Patient } from "features/patient/models/patient";
import { patientApi } from "features/patient/api/patientApi";
import { isEmptyOrWhiteSpace } from "app/helpers/stringHelper";

export interface ProfileAssignmentProps {
    patient: Patient;
    updatePatient: (patient: Patient) => void;
}

const ProfileAssignments: React.FC<ProfileAssignmentProps> = ({ patient, updatePatient }) => {

    const [programmes, setProgrammes] = useState<Programme[]>([]);
    const [devices, setDevices] = useState<Device[]>([]);

    useEffect(() => {
        programmeApi.getProgrammes(baseFiltersInitialState, paginationInitialState).then(response => setProgrammes(response))
        deviceApi.getDevices(deviceFiltersInitialState, paginationInitialState).then(response => setDevices(response))
    }, [])

    const onProgrammeAssign = useCallback((event: SelectChangeEvent) => {
        if (!isEmptyOrWhiteSpace(event.target.value)) {
            patientApi.assignProgramme(patient.id, event.target.value).then((response) => {
                updatePatient({
                    ...patient,
                    programmeId: response.programmeId,
                })
            })
        }
    }, [patient])

    const onProgrammeUnassign = useCallback(() => patientApi.unassignProgramme(patient.id).then((response) => {
        updatePatient({
            ...patient,
            programmeId: response.programmeId,
        })
    }), [patient])

    const onDeviceAssign = useCallback((event: SelectChangeEvent) => {
        if (!isEmptyOrWhiteSpace(event.target.value)) {
            patientApi.assignDevice(patient.id, event.target.value).then((response) => {
                updatePatient({
                    ...patient,
                    deviceId: response.deviceId,
                })
            })
        }
    }, [patient])

    const onDeviceUnassign = useCallback(() => {
        patientApi.unassignDevice(patient.id).then((response) => {
            updatePatient({
                ...patient,
                deviceId: response.deviceId,
            })
        })
    }, [patient])

    return (
        <Grid container spacing={1}>
            <Grid item>
                <Typography variant={'h6'} color={"textSecondary"}>Assignments</Typography>
            </Grid>
            <Grid container item justifyContent={"flex-end"}>
                <SelectInput
                    label={"Programme"}
                    value={patient.programmeId ?? ""}
                    onChange={onProgrammeAssign}
                >
                    <MenuItem disabled value={""}>None</MenuItem>
                    {
                        programmes.map((p, i) =>
                            <MenuItem key={`programme-${i}`} value={p.id}>{p.name}</MenuItem>)
                    }
                </SelectInput>
                <Button disabled={patient.programmeId === null} size={"small"}
                        onClick={onProgrammeUnassign}>Unassign</Button>
            </Grid>
            <Grid container item justifyContent={"flex-end"}>
                <SelectInput
                    label={"Device"}
                    value={patient.deviceId ?? ""}
                    onChange={onDeviceAssign}
                >
                    <MenuItem disabled value={""}>None</MenuItem>
                    {
                        devices.filter(d => d.assigned === false || d.id === patient.deviceId).map((d, i) =>
                            <MenuItem key={`device-${i}`}
                                      value={d.id}>{`${d.friendlyName} [${d.hardwareId}]`}</MenuItem>)
                    }
                </SelectInput>
                <Button disabled={patient.deviceId === null} size={"small"} onClick={onDeviceUnassign}>Unassign</Button>
            </Grid>
        </Grid>
    )
}
export { ProfileAssignments }