import { axiosApi } from "app/axios/axios";
import { Pagination } from "models/table/pagination";
import { UrlBuilder } from "app/builders/urlBuilder";
import { UserFilters } from "features/users/models/userFilters";
import { Patient } from "features/patient/models/patient";
import { BaseFilters } from "models/filters/baseFilters";
import { Programme } from "features/programme/models/programme";
import { CreateProgrammeRequest } from "features/programme/api/request/createProgrammeRequest";

class ProgrammeApi {

    private urlBuilder: UrlBuilder;

    constructor() {
        this.urlBuilder = new UrlBuilder("api/programme")
    }

    public async getProgrammes(filters: BaseFilters, pagination: Pagination): Promise<Programme[]> {

        const url = this.urlBuilder
            .withPagination(pagination)
            .withBaseFilters(filters)
            .build();

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async getProgramme(programmeId: string): Promise<Patient> {

        const url = this.urlBuilder
            .withRoute(programmeId)
            .build()

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            })
    }

    public async createProgramme(request: CreateProgrammeRequest): Promise<Programme> {

        const url = this.urlBuilder.build()

        return await axiosApi.post(url, JSON.stringify(request))
            .then(response => response.data)
            .catch(e => {
                return e;
            })
    }

    public async validateName(name: string): Promise<boolean> {

        const url = this.urlBuilder
            .withRoute("ValidName")
            .withRoute(name)
            .build()

        return await axiosApi.post(url).then(response => response.data)
            .catch(e => {
                return e;
            })
    }
}

const programmeApi = new ProgrammeApi();
export { programmeApi }