import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, CardContent, Container, Grid, IconButton, Menu, Typography, useTheme } from "@mui/material";
import { WelcomeMessage } from "components/heading/welcomeMessage";
import { IconCard } from "components/cards/iconCard";
import {
    BrowseGalleryRounded,
    BubbleChartRounded, ChevronRightRounded,
    VerifiedRounded,
    WatchLaterRounded
} from "@mui/icons-material";
import { Customer } from "features/customer/models/customer";
import { customerApi } from "features/customer/api/customerApi";
import { BaseFilters, baseFiltersInitialState } from "models/filters/baseFilters";
import { Pagination, paginationInitialState } from "models/table/pagination";
import { CustomerDirectory } from "features/customer/components/customerDirectory";
import { TitleCard } from "components/cards/titleCard";
import { FormikProps } from "formik";
import { CustomerInviteForm } from "features/customer/components/customerInviteForm";
import { CreateCustomerRequest } from "features/customer/api/requests/createCustomerRequest";

const AdminDashboard: React.FC = () => {

    const [customers, setCustomers] = useState<Customer[]>([])
    const [filters, setFilters] = useState<BaseFilters>(baseFiltersInitialState)
    const [pagination, setPagination] = useState<Pagination>(paginationInitialState);
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const open = Boolean(anchor);

    const formik = useRef<FormikProps<any>>(null);
    const theme = useTheme();


    useEffect(() => {
        customerApi.getCustomers(filters, pagination).then((response) => setCustomers(response))
    }, [filters, pagination])

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);
    
    const onCustomerCreate = useCallback((request: CreateCustomerRequest) => {
        customerApi
            .createCustomer(request)
            .then((val) => setCustomers(prevState => [...prevState, val]))
    }, [])
    
    return (
        <Container>
            <Grid container spacing={2} alignItems={"stretch"}>
                <Grid item xs={12} sm={12} md={12} lg={4.5} xl={3.5}>
                    <Card sx={{
                        backgroundColor: theme.palette.primary.main,
                    }}>
                        <CardContent>
                            <WelcomeMessage/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={7.5} xl={8.5} alignItems={"stretch"} spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <IconCard
                            title={"10"}
                            subtitle={"Pending"}
                            icon={<BrowseGalleryRounded fontSize={"large"} color={"disabled"}/>}
                            toolbar={<IconButton size={"small"} color={"primary"}><ChevronRightRounded/></IconButton>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <IconCard
                            title={"10"}
                            subtitle={"In Therapy"}
                            icon={<BubbleChartRounded fontSize={"large"} color={"info"}/>}
                            toolbar={<IconButton size={"small"} color={"primary"}><ChevronRightRounded/></IconButton>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <IconCard
                            title={"5"}
                            subtitle={"Waiting"}
                            icon={<WatchLaterRounded fontSize={"large"} color={"warning"}/>}
                            toolbar={<IconButton size={"small"} color={"primary"}><ChevronRightRounded/></IconButton>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <IconCard
                            title={"1"}
                            subtitle={"Complete"}
                            icon={<VerifiedRounded fontSize={"large"} color={"success"}/>}
                            toolbar={<IconButton size={"small"} color={"primary"}><ChevronRightRounded/></IconButton>}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <TitleCard
                        title={"Customers"}
                        toolbar={
                            <Button onClick={handleClick}>Create Customer</Button>
                        }
                    >
                        <CustomerDirectory
                            customers={customers}
                            pagination={pagination}
                            filters={filters}
                        />
                    </TitleCard>
                </Grid>
                <Grid item xs={12}>
                </Grid>
            </Grid>
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant={"menu"}
            >
                <Grid container spacing={2} sx={{ p: 2, maxWidth: "600px" }}>
                    <Grid item>
                        <Typography variant={'h6'}>Create Customer</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomerInviteForm
                            formik={formik}
                            onSubmit={onCustomerCreate}/>
                    </Grid>
                    <Grid container item justifyContent={"flex-end"} spacing={2}>
                        <Grid item>
                            <Button color={"error"} onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button color={"success"} onClick={() => formik.current?.submitForm()}>Send</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Menu>
        </Container>
    )
}
export { AdminDashboard }