import * as Yup from "yup";
import { DeviceType } from "features/device/models/deviceType";
import { debounce } from "lodash";
import { deviceApi } from "../api/deviceApi";

export const validateHardwareId = debounce(async (value: string | undefined): Promise<boolean> => {
    if(value === undefined)
        return false;
    
    return deviceApi.validateHardwareId(value).then(response => response).catch(() => false);
}, 500, { trailing: true})

export const validateFriendlyName = debounce(async (value: string | undefined): Promise<boolean> => {
    if(value === undefined)
        return false;

    return deviceApi.validateFriendlyName(value).then(response => response).catch(() => false);
}, 500, { trailing: true})

export const registerDeviceValidation = Yup.object().shape({
    // @ts-ignore
    hardwareId: Yup.string().required("Required").test("isHardwareIdUnique", "Hardware I.D already exists", async (value) => !await validateHardwareId(value)),
    // @ts-ignore
    friendlyName: Yup.string().required("Required").test("isFriendlyNameUnique", "Friendly Name already exists", async (value) => !await validateFriendlyName(value)),
    type: Yup.number().min(DeviceType.Unknown).max(DeviceType.Quest2).required("Required")
})