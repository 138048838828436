import React, { ChangeEvent, useCallback } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { flatten } from "app/helpers/objectHelper";
import { UserFilters } from "features/users/models/userFilters";
import { Pagination } from "models/table/pagination";
import { User } from "features/users/models/user";
import { userDirectoryColumns } from "features/customer/models/userDirectoryColumns";
import { Radio } from "@mui/material";
import { nameof } from "app/helpers/stringHelper";

export type UserDirectoryProps = {
    users: User[];
    filters: UserFilters;
    pagination: Pagination;
    selected?: string[] | undefined;
    updateSelected?: (selected: string[]) => void | undefined;
}

const UserDirectory: React.FC<UserDirectoryProps> = ({ users, filters, pagination, selected, updateSelected }) => {

    const handleUserSelect = useCallback((e: ChangeEvent<HTMLInputElement>) => {

        if (selected === undefined || updateSelected === undefined)
            return;
        
        console.log(e.target.value, e.target.checked)
        
        // @ts-ignore
        updateSelected(e.target.checked ? [...selected, e.target.value] : selected.filter(u => u.id === e.target.value.id)
        )
    }, [selected])

    const selection: GridColDef = {
        field: nameof<User>("id"),
        headerName: "",
        renderCell: (params: GridRenderCellParams) => {
            console.log("Params: ", params);
            console.log("Selected: ", selected)
            return (
                <Radio
                    checked={selected?.some(id => id === params.id)}
                    value={params.value}
                    onChange={handleUserSelect}
                />
            )
        }
            
    }

    const columns = selected ? [selection, ...userDirectoryColumns.filter(c => c.field !== nameof<User>("id"))] : userDirectoryColumns;

    console.log(selected)
    
    return (
        <DataGrid
            autoHeight
            columns={columns}
            rows={users.map((p) => flatten(p))}
            disableColumnMenu
            pagination
            paginationModel={pagination}
        />
    )
}
export { UserDirectory }