import { GridColDef } from "@mui/x-data-grid";
import { User } from "features/users/models/user";
import { getInitials, nameof } from "app/helpers/stringHelper";
import { Avatar, Checkbox } from "@mui/material";
import { CheckRounded, CloseRounded } from "@mui/icons-material";
import { Patient } from "features/patient/models/patient";
import { Programme } from "features/programme/models/programme";
import { ScenarioTemplate } from "features/programme/models/scenarioTemplate";

export const scenarioTemplateColumns: GridColDef[] = [
    {
        headerName: "Name",
        field: nameof<ScenarioTemplate>("name"),
        flex: 0.3
    },
    {
        headerName: "Description",
        field: nameof<ScenarioTemplate>("description"),
        flex: 0.25,
    },
    {
        headerName: "Configuration",
        field: nameof<ScenarioTemplate>("configuration"),
        flex: 0.5,
    }
]