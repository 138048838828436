import React, {ReactElement} from 'react';
import {Avatar, Grid, IconButton, Typography} from "@mui/material";
import {User} from "features/users/models/user";
import {getInitials} from "app/helpers/stringHelper";
import {MoreVert} from "@mui/icons-material";

export type ProfileHeadingProps = {
    name: string;
    email?: string;
    toolbar?: ReactElement;
    disableAvatar?: boolean;
}

const ProfileHeading: React.FC<ProfileHeadingProps> = ({name, email, toolbar, disableAvatar = false}) => {
    return (
        <Grid container spacing={2}>
            {!disableAvatar &&
                <Grid item>
                    <Avatar variant={"rounded"} sx={{
                        height: "100%",
                        fontSize: "2.0em",
                        pr: "1.0em",
                        pl: "1.0em"
                    }}>{getInitials(name.split(" "))}</Avatar>
                </Grid>
            }
            <Grid container item xs spacing={0.5}>
                <Grid item xs>
                    <Typography variant={"h5"}>{name}</Typography>
                </Grid>
                <Grid item>
                    <IconButton size={"small"}><MoreVert/></IconButton>
                </Grid>
                <Grid item xs={12}>
                    {toolbar && toolbar}
                </Grid>
            </Grid>
        </Grid>
    )
}
export {ProfileHeading}