import { axiosApi } from "app/axios/axios";
import { Pagination } from "models/table/pagination";
import { UrlBuilder } from "app/builders/urlBuilder";
import {MetricFilters} from "../models/metricFilters";
import {SessionStatistics} from "../models/sessionStatistics";
import { Metric } from "../models/metric";
import { Session } from "features/metrics/models/session";

class MetricApi {
    
    private urlBuilder: UrlBuilder;
    
    constructor() {
        this.urlBuilder = new UrlBuilder("api/metric")
    }

    public async getMetricsByPatient(patientId: string, filters: MetricFilters): Promise<Session[]> {
        
        const url = this.urlBuilder
            .withRoute("patient")
            .withRoute(patientId)
            .withMetricFilters(filters)
            .build();
        
        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async getRecentActivityByPatient(patientId: string): Promise<SessionStatistics[]> {

        const url = this.urlBuilder
            .withRoute("patient")
            .withRoute(patientId)
            .withRoute("recent-activity")
            .build();

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }
}

const metricApi = new MetricApi();
export { metricApi }