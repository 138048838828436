import React, { useEffect, useState } from 'react';
import { Props } from "models/interfaces/props";
import { Box } from "@mui/material";
import { NavDrawer } from "components/navigation/navDrawer";
import { useUserContext } from "app/contexts/userContext";
import { UserType } from "models/lookup/userType";
import { LoadingLayout } from "app/layouts/loadingLayout";
import { TechnicianBroker, TechnicianContext } from "app/contexts/technicianContext";
import { Technician } from "features/technician/models/technician";

const TechnicianLayout: React.FC<Props> = ({ children }) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [technician, setTechnician] = React.useState<Technician | undefined>(undefined)

    const { user } = useUserContext();

    useEffect(() => {
        setLoading(user !== null && user?.type !== UserType.Technician && user?.type !== UserType.Admin)
    }, [user])

    return (
        <TechnicianContext.Provider
            value={{ 
                technician: technician,
                updateTechnician: setTechnician
            }}
        >
            <TechnicianBroker>
                <LoadingLayout loading={loading}>
                    <Box display={"flex"} flexGrow={1}>
                        <NavDrawer/>
                        <Box component={"main"} flexGrow={1} p={3} display={"inline-flex"}>
                            {children}
                        </Box>
                    </Box>
                </LoadingLayout>
            </TechnicianBroker>
        </TechnicianContext.Provider>
    )
}
export { TechnicianLayout }