import { CSSProperties } from "react";
import { DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";

export const getCardStyling = (isDraggingOver: boolean, styleProps: DraggingStyle | NotDraggingStyle | undefined): CSSProperties =>
    ((isDraggingOver ? { ...draggingStyle, ...styleProps } : { ...defaultStyle, ...styleProps}))


const defaultStyle: CSSProperties = {
    marginBottom: 10
}

const draggingStyle: CSSProperties  = {
    ...defaultStyle
}